@import "../../setting.scss";

.edit-profile {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($color-black, 0.7);
  transition: all 0.5s;
  z-index: 99999;

  li {
    margin-left: 0;

    i {
      margin-left: 0;
    }
  }

  .popup-box {
    // background-color: $color-grayPrimary;
    background: linear-gradient(180deg, #37195e 0%, rgba(66, 29, 102, 0) 100%),
      #361a50;
    width: 570px;
    position: absolute;
    z-index: 99999;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @media (max-width: 767px) {
      width: 90%;
    }
  }

  .btn-close {
    width: 22px;
    height: 22px;
    border: solid 1px $color-white;
    @extend %flexCenter;
    position: absolute;
    top: 24px;
    right: 26px;
    border-radius: 50%;
    transition: all 0.5s;
    cursor: pointer;

    @media (max-width: 767px) {
      top: 5px;
      right: 5px;
    }
    i {
      color: $color-white;
      padding-top: 1px;
    }

    &:hover {
      background-color: $color-orange;
      border-color: $color-orange;
    }
  }

  li {
    margin-left: 0;

    i {
      margin-left: 0;
    }
  }

  .user-info {
    .save-btnList {
      display: flex !important;
      justify-content: flex-start;

      li {
        border: none !important;
        margin-left: 0 !important;
        padding: 0 !important;
        margin-right: 10px;
      }
    }

    figure {
      position: relative;

      &:hover {
        .upload-box {
          opacity: 1;
        }
      }
    }

    .upload-box {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba($color-black, 0.5);
      border-radius: 50%;
      top: 0;
      left: 0;
      opacity: 0;
      transition: all 0.5s;
      cursor: pointer;

      input {
        width: 100%;
        position: absolute;
        opacity: 0;
        z-index: 9;
        width: 100%;
        height: 100%;
        cursor: pointer;
      }

      label {
        font-size: 20px;
        position: absolute;
        z-index: 8;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: transparent !important;
      }
    }
  }
  .text-group {
    @media (max-width: 767px) {
      width: calc(100% - 80px);
    }
  }
  .text-group,
  .social-detail,
  .detail-box {
    position: relative;

    &:hover {
      .edit-btnList {
        opacity: 1;
      }
    }
  }

  .detail-box {
    .edit-btnList {
      li {
        width: auto !important;
      }
    }
  }

  .detail-box {
    .edit-btnList {
      @media (max-width: 767px) {
        left: auto;
        right: 0;
      }
    }
    .name-group {
      input {
        margin-bottom: 5px;
      }
      @media (max-width: 767px) {
        max-width: calc(100% - 80px);
      }

      .field-error {
        color: #940000;
        font-size: 12px;
        margin-bottom: 5px;
      }
    }
  }

  .check-group {
    margin-left: auto;
    position: relative;
    margin-bottom: 10px;

    input {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      z-index: 2;
      cursor: pointer;

      &:checked + label:before {
        opacity: 1;
      }
    }

    label {
      font-size: 12px !important;
      font-weight: 400;
      color: rgba($color-white, 0.5);
      position: relative;
      padding-left: 28px;
      margin-bottom: 0;
      text-transform: capitalize !important;

      &:after,
      &:before {
        content: "";
        position: absolute;
      }

      &:after {
        width: 16px;
        height: 16px;
        background-color: rgba(196, 196, 196, 0.2);
        border-radius: 3px;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }

      &:before {
        width: 8px;
        height: 4px;
        border-left: solid 2px $color-white;
        border-bottom: solid 2px $color-white;
        left: 3px;
        top: calc(50% - 1px);
        transform: translateY(-50%) rotate(-45deg);
        opacity: 0;
      }
    }
  }

  .dob-fields {
    label {
      width: 100%;
      font-size: 10px;
      color: rgba($color-white, 0.4);
      text-transform: uppercase;
      margin-bottom: 5px;
      display: block;
    }

    .field-group {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-bottom: 10px;

      .field {
        &:first-of-type,
        &:nth-of-type(2) {
          width: 58px;
        }

        &:last-of-type {
          width: 100px;
        }

        margin-right: 10px;

        input {
          background-color: rgba(196, 196, 196, 0.2);
          font-size: 14px;
          padding: 5px 10px;
          border: none;
          outline: none;
          border-radius: 3px;
          width: 100%;
        }
      }
    }

    .form-group {
      margin-bottom: 0;
      margin-left: 10px;

      &:first-of-type {
        margin-left: 0;
      }

      &.month {
        width: 75px;
      }

      &.day {
        width: 75px;
      }

      &.year {
        width: 102px;
      }
    }
  }

  .edit-links {
    .form-group {
      display: flex;
      margin-bottom: 10px;

      label {
        width: 100px;
        font-size: 14px;
      }

      input {
        background-color: rgba(196, 196, 196, 0.2);
        border: none;
        outline: none;
        flex: 1;
        font-size: 14px;
        border-radius: 3px;
        padding: 6px 15px;
        margin-bottom: 0;
      }
    }
  }

  .edit-btnList {
    position: absolute;
    left: 100%;
    top: 0;
    width: 100px;
    opacity: 0;
    transition: all 0.5s;
    @media (max-width: 767px) {
      width: 70px;
    }

    li {
      color: #dadada;
      padding: 0px 10px;
      border-left: solid 1px #dadada;
      line-height: 1;
      font-size: 14px;
      display: inline-block;
      list-style: none;
      cursor: pointer;

      &:first-of-type {
        border-left: none;
        width: auto !important;
      }
    }
  }

  textarea,
  input {
    width: 100%;
    background-color: transparent;
    padding: 0;
    border: none;
    outline: none;
    resize: none;
    padding: 10px;
    background-color: rgba(196, 196, 196, 0.2);
    font-size: 14px;
    margin-bottom: 5px;
  }

  .save-btnList {
    text-align: right;
    display: block !important;

    li {
      width: auto !important;
      margin-left: 10px;
      display: inline-block;
      list-style: none;
    }
  }

  .profile-detail {
    padding: 40px;
    padding-right: 100px;
    @media (max-width: 767px) {
      padding: 20px;
      // padding-right: 50px;
    }
    p {
      font-size: 14px;
    }

    .btn-box {
      display: flex;
      justify-content: space-between;

      li {
        width: calc(50% - 5px);
        cursor: pointer;
      }
    }

    .user-info {
      display: flex;
      align-items: center;
      margin-bottom: 35px;

      figure {
        width: 80px;
        height: 80px;

        img {
          border-radius: 50%;
          width: 100%;
          height: 100%;
          -o-object-fit: cover;
          object-fit: cover;
        }
      }

      .detail-box {
        flex: 1;
        padding-left: 35px;
        @media (max-width: 767px) {
          padding-left: 15px;
        }

        .date-group {
          position: relative;
        }

        h2 {
          margin-bottom: 5px;
          @media (max-width: 767px) {
            margin-bottom: 0;
            font-size: 20px;
          }
        }

        li {
          list-style: none;
          font-size: 14px;
          color: rgba($color-white, 0.6);
          display: inline-block;
          border-left: solid 1px rgba($color-white, 0.6);
          padding: 0px 5px;
          line-height: 1;

          &:first-of-type {
            border-left: 0;
          }
        }
      }
    }

    .send-message {
      margin-right: -50px;
      position: relative;

      input {
        background: rgba(196, 196, 196, 0.2);
        border-radius: 3px;
        color: $color-white;
        border: none;
        outline: none;
        width: 100%;
        padding: 8px 10px;
        font-size: 14px;
        padding-right: 60px;
      }

      ul {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 5px;

        li {
          display: inline-block;
          list-style: none;
          color: rgba($color-white, 0.6);
          margin: 0 5px;

          button {
            background-color: transparent;
            border: none;
            color: rgba($color-white, 0.6);
          }
        }
      }
    }

    .btn-list {
      display: flex;
      justify-content: space-between;

      li {
        display: inline-block;
        width: calc(50% - 5px);

        button {
          background-color: rgba($color-white, 0.5);
          color: $color-black;
          font-size: 14px;
          border-radius: 3px;
          width: 100%;
          display: inline-block;
          border: none;
          padding: 8px 15px;
        }
      }
    }
    .social-detail {
      @media (max-width: 676px) {
        .edit-btnList {
          left: unset;
          right: 0;
        }
      }
    }
    .text-group,
    .social-detail {
      margin-top: 35px;

      h6 {
        color: rgba($color-white, 0.4);
        margin-bottom: 8px;
        font-size: 14px;
        font-weight: 400;
      }
    }

    .social-detail {
      h6 {
        margin-bottom: 20px;
      }

      ul {
        display: flex;

        li {
          display: inline-block;
          width: 25%;

          figure {
            width: 30px;
            height: 30px;
            background-color: #dadada;
            border-radius: 50%;
            @extend %flexCenter;
            margin: 0 auto 10px;

            i {
              color: $color-black;
            }
          }

          a {
            text-decoration: none;
            color: $color-white;
          }

          p {
            text-align: center;
            font-size: 14px;
          }
        }
      }
    }

    .email-group {
      margin-top: 30px;

      h6 {
        color: rgba($color-white, 0.4);
        margin-bottom: 8px;
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 5px;
      }

      .text-group {
        position: relative;
        width: 100%;
        display: flex;
        align-items: center;
        margin-top: 0;

        .status-box {
          margin-left: 20px;

          .confirmed {
            color: #68bd5b;
            font-size: 12px;
            display: inline-block;
            margin-bottom: 3px;
          }
        }

        .edit-btn {
          position: absolute;
          top: 0;
          left: 100%;
          background-color: transparent;
          color: #fff;
          border: none;
          outline: none;
        }
      }
    }
  }
}
