@import '../../setting.scss';

.login-layout {
    min-height: 100vh;
    padding-top: 50px;
    padding-bottom: 50px;
    background: -webkit-gradient(linear, left top, left bottom, from(#3C1C64), to(rgba(66, 29, 102, 0))), #4D2472;
    background: linear-gradient(180deg, #3C1C64 0%, rgba(66, 29, 102, 0) 100%), #4D2472;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;

    .container {
        max-width: 600px;
        background: -webkit-gradient(linear, left top, left bottom, from(#3C1C64), to(rgba(66, 29, 102, 0))), #4D2472;
        background: linear-gradient(180deg, #3C1C64 0%, rgba(66, 29, 102, 0) 100%), #4D2472;
        margin: 0 auto;
        border-radius: 6px;
        padding: 0;
    }
}
