@import '../../setting.scss';

.stream-box {
		&.active{
			@media (max-width: 767px){
				display: none;
			}
		}
    #twitch-embed {
        height: 100%;
    }

    iframe {
        width: 100%;
        height: 100%;
    }

    figure {
        width: 100%;
        height: 100%;
        position: relative;

        &:after {
            background: -webkit-gradient(linear, left bottom, left top, from(#8A46DF), to(rgba(131, 46, 220, 0)));
            background: linear-gradient(0deg, #8A46DF 0%, rgba(131, 46, 220, 0) 100%);
            opacity: 0.5;
            border-radius: 0px 0px 10px 10px;
            bottom: 0;
            left: 0;
            content: "";
            width: 100%;
            height: 275px;
            position: absolute;



        }

        p {
            font-weight: bold;
            font-size: 24px;
            color: $color-white;
            position: absolute;
            top: 50%;
            left: 50%;
            -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
        }

        img {
            -o-object-fit: cover;
            object-fit: cover;
            width: 100%;
            height: 100%;
            -webkit-filter: grayscale(1);
            filter: grayscale(1);
        }
    }
}
