.enter-code {
  background-color: #361a50;
  padding-top: 120px;
  justify-content: flex-start;

  .inner-header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    text-align: center;
    padding: 15px 0;
    background-color: rgba(#ffffff, 0.1);
  }
  h2 {
    margin-bottom: 30px;
  }
  form {
    width: 300px;
    .form-group {
      width: 100%;
      .form-control {
        width: 100%;
        background-color: transparent;
        border: solid 1px #fff !important;
        border-radius: 4px;
        text-align: center;
        &.error {
          border: solid 1px #f70404 !important;
        }
      }
      .error-box {
        p {
          text-align: center;
          color: #f70404;
          margin-top: 5px;
          font-size: 14px;
        }
      }
    }
    .btn-box {
      text-align: center;
      margin-top: 30px;
      .btn {
        &:disabled {
          opacity: 0.5;
        }
      }
    }
  }
}
