.edit-event {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: -1;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;

    &.active {
        opacity: 1;
        z-index: 999999999;
    }

    .box {
        width: 620px;
        position: relative;

        .btn-close {
            position: absolute;
            top: 0;
            left: 100%;
            transform: translate(-50%, -50%);
            background-color: #5F306B;
            width: 40px;
            height: 40px;
            border: none;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;

            i {
                font-size: 30px;
            }

        }
    }
}
