@import '../../setting.scss';

.recover-box {
    padding: 50px;
    text-align: center;
    @extend %flexCenter;
    height: 100vh;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    background-color: $color-grayPrimary;

    h2 {
        margin-bottom: 20px;

    }
}
