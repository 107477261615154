.confirmation-box {
    padding: 90px 100px 170px;
    text-align: center;

    figure {
        width: 150px;
        margin: 0 auto 70px;
    }

    h3 {
        font-size: 36px;
        margin-bottom: 30px;
    }

    p {
        font-size: 24px;
    }

    .btn {
        margin-top: 70px;
        min-width: 230px;
        padding: 10px 10px;
    }
}
