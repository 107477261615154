.create-event-box {
    width: 620px;
    background: linear-gradient(180deg, #37195E 0%, rgba(66, 29, 102, 0) 100%), #361A50;
    box-shadow: 0px 0px 29px #000000;
    border-radius: 10px;
    padding: 50px;

    .field-group {
        display: flex;
        align-items: center;
        margin-bottom: 30px;

        &.btn-group {
            justify-content: flex-end;

            .btn[disabled] {
                opacity: 0.6;
                cursor: not-allowed;
            }
        }

        figure {
            width: 110px;
            height: 110px;
            margin-right: 30px;
            position: relative;
            border-radius: 50%;
            overflow: hidden;

            &:after {
                background-color: rgba(0, 0, 0, 0.3);
                content: "";
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                position: absolute;
                border-radius: 50%;

            }

            .upload-box {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                z-index: 99;

                .btn {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    background-color: transparent !important;
                    font-size: 20px;
                    color: #ffffff;
                }

                input {
                    width: 100%;
                    height: 100%;
                    opacity: 0;
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 9;

                }
            }
        }

        .form-group {
            flex: 1;

            label {
                color: #FFFFFF;
                font-size: 14px;
                // margin-bottom: 10px;
                display: block;
                opacity: 0.7;
            }

            .flex {
                justify-content: space-between;
                margin-bottom: 10px;
                align-items: center;

                .switch-box {
                    display: flex;
                    align-items: center;

                    h5 {
                        color: #FFFFFF;
                        font-size: 14px;
                        display: block;
                        opacity: 0.7;
                        font-weight: 400;
                        margin-right: 10px;
                    }
                }

                .switch {
                    position: relative;
                    display: inline-block;
                    width: 35px;
                    height: 18px;
                    margin-bottom: 0;
                }

                .switch input {
                    opacity: 0;
                    width: 0;
                    height: 0;
                }

                .slider {
                    position: absolute;
                    cursor: pointer;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background-color: #918D90;
                    transition: .4s;
                }

                .slider:before {
                    position: absolute;
                    content: "";
                    height: 16px;
                    width: 16px;
                    left: 0px;
                    bottom: 0px;
                    background-color: #52465E;
                    border: solid 1px #fff;
                    transition: .4s;
                }

                input:checked+.slider {
                    background-color: #B94D9B;
                }

                input:checked+.slider:before {
                    background-color: #E127AD;
                }

                input:focus+.slider {
                    // box-shadow: 0 0 0 4px rgba(21, 156, 228, 0.7);
                    outline: none;
                }

                input:checked+.slider:before {
                    transform: translateX(17px);
                }

                .slider.round {
                    border-radius: 34px;
                }

                .slider.round:before {
                    border-radius: 50%;
                }
            }

            input,
            textarea {
                width: 100%;
                background-color: transparent;
                resize: none;
                outline: none;
                border: solid 1px #ffffff !important;
                border-radius: 3px;

                font-size: 14px;

                &.name-control {
                    border: none !important;
                    border-radius: 0;
                    border-bottom: solid 1px #ffffff !important;
                    box-shadow: none !important;
                    padding: 5px 0px;
                    font-size: 24px;

                    &::placeholder {
                        color: #FFFFFF;
                        font-size: 24px;
                        opacity: 0.6;
                    }
                }
            }

            textarea {
                padding: 10px 15px;
                ;
            }
        }
    }
}
