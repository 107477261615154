@import '../../setting.scss';

.welcome-popup {

    .popup-box {
        background-color: #361A50;
        padding: 40px 0 60px;
        width: 1020px;
        text-align: center;

        @media (max-width: 1200px) {
            width: 950px;
        }

        @media (max-width: 992px) {
            width: 750px;
            padding: 30px 0;
        }

        @media (max-width: 767px) {
            padding-bottom: 0;
            width: 100%;
            top: auto;
            bottom: 0;
            left: 0;
            transform: translate(0, 0);
        }

        .form-group {
            .input-group {
                position: relative;
            }
        }

        &.box-sm,
        &.box-xm {
            width: 620px;
            padding: 70px 100px 60px;

            @media (max-width: 767px) {
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding: 70px 70px 60px !important;

                h2 {
                    display: block;
                    font-size: 24px;
                }

                p {
                    text-align: center;
                }

            }

            @media (max-width:480px) {
                padding: 50px 50px 50px !important;
            }

            .form-error {
                p {
                    color: #FF0000;
                    font-size: 14px;
                    margin-top: 10px;

                }
            }

            .btn-close {
                top: 0;
                left: 100%;
                transform: translate(-50%, -50%);
                background-color: #5F306B;
                width: 40px;
                height: 40px;
                border: none;
                cursor: pointer;

                i {
                    font-size: 30px;
                }

                @media (max-width: 767px) {
                    top: 20px;
                    left: auto;
                    right: 10px;
                    transform: translate(0, 0);
                    background-color: transparent;
                    width: 40px;
                    height: 30px;
                }

            }

            .btn-back {
                position: absolute;
                top: 20px;
                left: 30px;
                cursor: pointer;

                i {
                    font-size: 25px;
                }


            }

            form {
                margin-top: 50px;

                @media (max-width: 767px) {
                    margin-top: 30px;
                }
            }

            .form-group {
                margin-bottom: 40px;
                position: relative;

                input {
                    width: 100%;
                    border: 1px solid $color-white !important;
                    background-color: transparent;
                    border-radius: 6px;
                    font-size: 20px;
                    padding: 0px 15px;
                    height: 50px;
                    text-align: center;
                    font-size: 18px;

                    @media (max-width: 767px) {
                        font-size: 16px;
                    }
                }

                .error-input {
                    position: absolute;
                    top: 100%;
                    text-align: center;
                    width: 100%;

                    p {
                        color: #FF0000;
                        font-size: 14px;
                        margin-top: 2px;


                    }
                }
            }

            .btn-pink {
                padding: 10px 20px;
                min-width: 230px;
                font-size: 18px;

                @media (max-width: 767px) {
                    font-size: 16px;
                    min-width: 170px;
                }
            }

            .skip-button {
                font-size: 16px;
                color: $color-white;
                line-height: 1.3em;
                display: block;
                text-decoration: none;
                margin-top: 25px;
                text-align: center;
                background-color: transparent;
                border: none;
                outline: none;
                margin: 25px auto 0;
                cursor: pointer;
            }
        }

        &.login-popup {
            .form-message {
                margin-top: 10px;
            }
        }

        &.box-xm {
            padding: 70px 145px 60px;

            .form-group {
                margin-bottom: 40px;

            }

            .btn-pink {
                width: 100%;
            }

            .text-box {
                p {
                    font-size: 12px;
                    margin-top: 20px;

                    a {
                        color: #E127AD;
                    }
                }
            }

            h2 {
                font-size: 24px;
                font-weight: 400;
            }
        }

        h2 {
            font-size: 36px;
            font-weight: 700;
            margin-bottom: 15px;

            &.mobile-text {
                display: none;
            }

            @media (max-width: 992px) {
                font-size: 25px;
                margin-bottom: 5px;
            }

            @media (max-width: 767px) {
                &.mobile-text {
                    display: block;
                    font-size: 20px;
                }

                &.desktop-text {
                    display: none;
                }
            }
        }

        p {
            figure {
                display: inline-block;
                vertical-align: middle;
                margin-right: 20px;
                font-size: 24px;

                img {
                    width: 40px;
                    height: 40px;
                }


            }

            @media (max-width: 992px) {
                font-size: 16px
            }

            @media (max-width: 767px) {
                text-align: left;
                padding: 0px 30px;
            }
        }

        .colmn-section {
            margin-top: 50px;
            display: flex;

            @media (max-width: 992px) {
                margin-top: 20px;
            }

            @media (max-width: 767px) {
                flex-wrap: wrap;
                margin-top: 0;
            }

            .colmn-box {
                width: 50%;
                padding: 10px 70px;
                border-left: solid 1px rgba($color-white, 0.6);

                @media (max-width: 992px) {
                    padding: 10px 20px;
                }

                @media (max-width: 767px) {
                    width: 100%;
                    border: none;
                    padding: 30px 30px;
                }

                .btn-pink {
                    min-width: auto;
                    font-size: 18px;
                    font-weight: 400;
                    padding: 15px 30px;

                    @media (max-width: 767px) {
                        font-size: 14px;
                        padding: 11px 28px;
                    }
                }

                .form-error {
                    p {
                        color: #FF0000;
                        font-size: 14px;
                        margin-top: 2px;
                    }
                }

                .form-message {
                    margin-top: 20px;
                }

                &:first-of-type {
                    border-left: none;

                    @media (max-width: 767px) {
                        position: relative;

                        &:after {
                            border-bottom: solid 1px rgba($color-white, 0.6);
                            width: calc(100% - 100px);
                            height: 1px;
                            position: absolute;
                            bottom: 0;
                            left: 50%;
                            transform: translateX(-50%);
                            content: "";

                        }

                        &:before {
                            background-color: #361A50;
                            content: "or";
                            font-size: 16px;
                            font-style: italic;
                            top: 100%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            position: absolute;
                            z-index: 9;
                            padding: 0px 30px 2px;
                            font-weight: 400;

                        }

                    }
                }

                h3 {
                    font-size: 24px;
                    margin-bottom: 30px;

                    @media (max-width: 992px) {
                        font-size: 20px;
                        margin-bottom: 15px;
                    }

                    @media (max-width: 767px) {
                        display: none;
                    }
                }

                .form-group {
                    position: relative;

                    label {
                        display: block;
                        font-size: 18px;
                        margin-bottom: 10px;
                        font-weight: 400;

                        @media (max-width: 992px) {
                            font-size: 16px;
                        }

                        @media (max-width: 767px) {
                            display: none;
                        }
                    }

                    .input-group {
                        display: flex;
                        margin-bottom: 30px;
                        align-items: center;
                        position: relative;

                        &.guest-group {
                            align-items: flex-start;

                            .input-nameGroup {
                                .error-input {
                                    position: static;
                                }
                            }
                        }

                        img {
                            margin-right: 20px;
                            width: 50px;
                            border-radius: 50%;
                            height: 50%;
                            -o-object-fit: cover;
                            object-fit: cover;

                            @media (max-width: 767px) {
                                display: none;
                            }

                        }

                        input {
                            flex: 1;
                            border: 1px solid $color-white !important;
                            background-color: transparent;
                            border-radius: 6px;
                            font-size: 20px;
                            padding: 0px 15px;
                            height: 50px;

                            @media (max-width: 767px) {
                                font-size: 16px;
                            }
                        }

                        .input-nameGroup {
                            flex: 1;
                            position: relative;

                            .error-input {
                                p {
                                    text-align: left;
                                }
                            }

                            input {
                                width: 100%;
                            }
                        }
                    }

                    .error-input {
                        position: absolute;
                        top: 100%;
                        text-align: center;
                        width: 100%;

                        p {
                            color: #FF0000;
                            font-size: 14px;
                            margin-top: 2px;

                            @media (max-width: 767px) {
                                padding: 0 0 !important;
                            }
                        }
                    }

                }
            }
        }

        &.upload-main {


            p {
                font-size: 24px;
            }

            @media (max-width: 767px) {
                padding: 0px 30px;

                p {
                    font-size: 18px;
                }

                .btn-pink {
                    width: 170px;
                    margin: 0 auto;
                }
            }

            .error-input {
                text-align: center;
                font-size: 14px;
                margin-bottom: 10px;

                p {
                    font-size: 14px;
                    color: #FF0000;
                    margin-top: 0px;
                }
            }

            .upload-box {
                width: 96px;
                height: 96px;
                margin: 40px auto;
                position: relative;

                @media (max-width: 767px) {
                    margin: 20px auto 30px;
                }

                figure {
                    background-color: rgba($color-white, 0.5);
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    overflow: hidden;

                    img {
                        width: 30px;
                    }
                }

                input {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 9;
                    opacity: 0;
                }

                span {
                    position: absolute;
                    width: 24px;
                    height: 24px;
                    border-radius: 50%;
                    background: #E127AD;
                    bottom: -7px;
                    right: 5px;

                    i {
                        position: absolute;
                        top: 5px;
                        left: 7px;
                        font-size: 14px;
                    }
                }
            }
        }

        &.dob-form {
            width: 787px;
            padding: 85px 125px 50px 115px;

            @media (max-width: 767px) {
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding: 0px 30px;

                h2 {
                    display: block;
                    font-size: 24px;
                    margin-bottom: 15px;
                }

                h4 {

                    font-size: 18px !important;
                    margin-bottom: 40px;
                }

                p {
                    display: none;
                }

            }

            h4 {
                font-size: 24px;
                line-height: 1.3em;
                font-weight: 400;
            }

            p {
                margin: 40px 0 5px;
            }

            form {
                margin-top: 0;

                .form-group {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 55px;

                    @media (max-width: 767px) {
                        margin-bottom: 30px;
                    }

                    .input-group {
                        width: 158px;

                        &:last-of-type {
                            width: 183px;
                        }

                        @media (max-width: 767px) {
                            width: calc(33.33% - 10px) !important;
                        }
                    }
                }
            }

            &.bio-form {
                padding: 85px 91px 50px;

                @media (max-width: 767px) {
                    padding: 0px 30px;
                }

                form {
                    .form-group {
                        margin: 35px 0;

                        .input-group {
                            width: 100%;

                            @media (max-width: 767px) {
                                width: 100% !important;
                            }

                            textarea {
                                width: 100%;
                                height: 113px;
                                border-radius: 6px;
                                border: solid 1px $color-white !important;
                                background-color: transparent;
                            }
                        }

                        @media (max-width: 767px) {
                            margin-top: 0;

                            .input-group {
                                width: 100% !important;

                                textarea {
                                    font-size: 16px;
                                }
                            }
                        }
                    }
                }
            }

            &.link-form {
                padding: 85px 130px 50px 170px;

                @media (max-width: 767px) {
                    padding: 0px 30px;
                }

                form {
                    margin-top: 30px;

                    .btn-pink {
                        margin-top: 20px;
                    }

                    .form-group {
                        margin: 0px 0 30px;

                        label {
                            margin-right: 0;
                            font-size: 14px;
                            line-height: 1;
                            min-width: 110px;
                            text-align: left;

                            @media (max-width: 767px) {
                                display: none;
                            }

                        }

                        .input-group {
                            width: 100%;
                            display: flex;
                            align-items: center;

                            @media (max-width: 767px) {
                                width: 100% !important;
                            }

                            input {
                                height: 27px;
                                border-radius: 3px;
                                text-align: left;
                                font-size: 14px;
                            }

                            .error-input {
                                text-align: left;
                                font-size: 12px;

                                p {
                                    font-size: 12px;
                                    margin-top: 0px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

}
