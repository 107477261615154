@import '../../setting.scss';

.whoHere-box {
    background-color: rgba($color-white, 0.1);
    border-radius: 6px;
    overflow: hidden;

    h3 {
        font-size: 18px;
        color: $color-white;
        text-align: center;
        padding: 15px 0px 20px;
        height: 60px;

        @media (max-width:767px) {
            font-size: 16px;
            height: 50px;
            padding: 15px 0;
        }
    }

    .user-listing {
        height: calc(100% - 60px);
        overflow: auto;

        @media (max-width:767px) {
            height: calc(100% - 50px);
        }

    }

    .colmn-box {
        width: 16.666%;
        text-align: center;
        margin-bottom: 30px;

        @media (max-width: 1500px) {
            margin-bottom: 20px;
        }

        @media (max-width: 767px) {
            width: 20%;
        }

        @media (max-width: 480px) {
            width: 33.33%;
        }

        figure {
            margin: 0 auto;
            width: 68px;
            height: 68px;
            border-radius: 50%;
            overflow: hidden;

            @media(max-width:1500px) {
                width: 50px;
                height: 50px;
            }

            img {
                width: 100%;
                height: 100%;
                -o-object-fit: cover;
                object-fit: cover;
            }
        }

        p {
            margin-top: 5px;
            max-width: 100%;
            text-overflow: ellipsis;
            overflow: hidden;
            font-size: 14px;
            font-weight: 400;
            white-space: nowrap;
            padding: 0px 5px;
        }


    }
}
