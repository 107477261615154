@import "../../setting.scss";
.feed-list-section {
  position: fixed;
  top: 0;
  left: 100%;
  z-index: 9999999999;
  width: 100%;
  height: 100%;
  background-color: #13143c;
  display: none;
  transition: left 0.5s;
  @media (max-width: 767px) {
    display: block;
  }
  &.active {
    left: 0;
  }
  .inner-header {
    background-color: #26264b;
    display: flex;
    align-items: center;
    padding: 15px 20px;
    h2 {
      font-size: 14px;
      padding-left: 10px;
    }
  }
  .user-detail {
    margin-bottom: -15px;
    position: relative;
  }
  .box-listing {
    padding: 0px 15px;
    height: calc(100% - 47px);
    overflow: auto;
    .main-box {
      margin-top: 15px;
      position: static;
      height: unset;
      .image-box {
        // margin-top: 10px;
      }
      .user-detail {
        h2 {
          font-size: 14px;
          margin-bottom: 5px;
        }
        .inner-box {
          display: flex;
          figure {
            width: 30px;
            margin-right: 15px;
            border-radius: 50%;
            overflow: hidden;
          }
          p {
            background-color: #fff;
            font-size: 14px;
            color: #000;
            line-height: 1;
            padding: 5px 10px;
          }
        }
      }
      .mobile-box {
        display: flex;
        justify-content: space-between;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        // display: block;
        background-color: #26264b;
        padding: 10px 15px;
        text-align: center;
        .messsage-btn,
        .see-more-btn,
        ul {
          display: block;
        }

        .messsage-btn {
          background-color: transparent;
          color: #fff;
          border: none;
          outline: none;
          font-size: 12px;
          text-transform: capitalize;

          img {
            vertical-align: middle;
          }
        }
        ul {
          display: flex;
          transform: translate(0);
          position: relative;
          margin-right: -5px;
          li {
            display: flex;
            padding: 0;
            border: none;
            background-color: transparent;
            border-radius: 0;
            align-items: center;
            margin-bottom: 0;
            padding-left: 10px;
            img {
              width: 12px;
              margin-right: 3px;
            }
          }
        }
      }
    }
  }
}

.listview-send-box {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s;
  &.active {
    z-index: 9999999999;
    opacity: 1;
    visibility: visible;
  }
  .backDrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999999999;
  }

  .send-box {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 15px 25px 20px;
    background-color: #381a5c;
    transform: translateY(100%);
    transition: all 0.5s;
    opacity: 0;
    z-index: -111;
    &.active {
      transform: translateY(0);
      z-index: 999999999999999999;
      opacity: 1;
    }

    // @media (max-width: 1600px) {
    //   width: 350px;
    // }

    // @media (max-width: 1400px) {
    //   bottom: 35px;
    //   left: 10px;
    //   transform: translateX(0);
    // }

    .post-box {
      display: none;

      @media (max-width: 767px) {
        display: flex;
        align-items: center;
        margin-bottom: 14px;

        figure {
          width: 68px;
          height: 40px;
          margin-right: 15px;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        p {
          font-size: 14px;
          opacity: 0.8;
        }
      }
    }

    form {
      position: relative;
    }

    input {
      border: solid 1px rgba($color-white, 0.5) !important;
      background-color: transparent;
      width: 100%;
      padding: 4px 15px;
      font-size: 14px;
      padding-right: 60px;
    }

    ul {
      right: 0;
      display: flex;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      z-index: 9;

      li {
        display: flex;
        border: none;
        padding: 0;
        margin-right: 14px;
        margin-bottom: 0;
        background-color: transparent;
        align-items: center;
        justify-content: center;

        &:last-of-type {
          order: -1;
          margin-right: -10px;
        }

        button {
          border: none;
          outline: none;
          background-color: transparent;
          color: $color-white;
        }
      }
    }
  }
}

.make-popup {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #863399;
  z-index: 9999999;
  text-align: center;
  padding: 30px 50px;
  figure {
    width: 33px;
    height: 33px;
    margin: 0 auto;
    border-radius: 50%;
    border: solid 2px $color-white;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 15px;
    }
  }
  .btn-close {
    background-color: transparent;
    border: none;
    outline: none;
    position: absolute;
    top: 10px;
    right: 10px;
  }
  .btn-pink {
    background-color: rgba(255, 255, 255, 0.3);
  }
  p {
    padding: 10px 0 30px;
  }
}
