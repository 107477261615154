@import "../../setting.scss";
.loading-box {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba($color-black, 0.8);
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: center;
  opacity: 0;
  z-index: -1;
  transition: all 0.5s;
  &.active {
    opacity: 1;
    z-index: 99999999999999999;
  }
  .inner-box {
    figure {
      width: 100px;
    }
  }
}
