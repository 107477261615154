@import './setting.scss';


* {
    padding: 0px;
    margin: 0px;
    box-sizing: border-box;
}

body {
    font-size: $font-size-body;
    color: $color-white;
    background-color: $color-grayDarker;
    font-family: $font-family-body;
    -webkit-text-size-adjust: none;
    position: relative;
    width: 100%;
    overflow-x: auto;

    // min-width: 1440px;
    @media (max-width: 992px) {
        #Smallchat {
            display: none;
        }

    }




}

input,
button,
textarea,
.btn {
    font-family: $font-family-body;
    font-size: $font-size-body;


    &:focus {
        outline: none;
    }
}

input,
textarea {
    color: $color-white;

    &::-webkit-input-placeholder {
        color: rgba($color-white, 0.5);
    }

    &::-moz-placeholder {
        color: rgba($color-white, 0.5);
    }

    &:-ms-input-placeholder {
        color: rgba($color-white, 0.5);
    }

    &::-ms-input-placeholder {
        color: rgba($color-white, 0.5);
    }

    &::placeholder {
        color: rgba($color-white, 0.5);
    }
}

input,
textarea {
    border: solid 1px transparent !important;
    transition: all 0.5s;
    box-shadow: 0 0 0 rgba(255, 255, 255, 0.3);

    &:focus {
        border: 1px solid #959595 !important;
        box-shadow: 0px 0px 4px rgba(255, 255, 255, 0.3);
    }
}

.back-drop {
    position: fixed;
    z-index: 99;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    top: 0;
    left: 0;

}

figure {
    img {
        width: 100%;
    }
}

p {
    a {
        color: $color-primary;
    }
}

.container {
    max-width: $container;
    margin: 0 auto;
    padding: 0 15px;
}

img {
    max-width: 100%;
    vertical-align: top;
}

.color-white {
    * {
        color: $color-white;
    }
}

.bg-control {
    background-size: cover;
    background-repeat: no-repeat;
}

.slick-slide {
    outline: none;
}


.text-center {
    text-align: center;
}

.text-left {
    text-align: left !important;
}

.text-right {
    text-align: right;
}

figure {
    margin: 0;
}

.login-btn {
    font-size: 14px;
    font-weight: 400;
    border: 1px solid $color-white !important;
    border-radius: 3px;
    background-color: transparent !important;
    outline: none;
    color: $color-white;
    padding: 5px 15px;
}

.signUp-btn {
    font-size: 14px;
    font-weight: 400;
    border: 1px solid $color-white !important;
    border-radius: 3px;
    background-color: $color-white !important;
    outline: none;
    color: $color-black !important;
    padding: 5px 15px;
}

.flex {
    display: flex;
    flex-wrap: wrap;

    &.column {
        flex-flow: column wrap;
    }

    &.space-between {
        justify-content: space-between;
    }

    &.space-center {
        justify-content: center;
    }

    &.space-around {
        justify-content: space-around;
    }

    &.space-evenly {
        justify-content: space-evenly;
    }

    &.space-end {
        justify-content: flex-end;
    }

    // Items alignment
    &.items-align-center {
        align-items: center;
    }

    &.items-align-end {
        align-items: flex-end;
    }

    &.align-stretch {
        align-items: stretch !important;
    }

    &.align-start,
    &.items-align-start {
        align-items: flex-start;
    }
}

.form-group {
    .form-control {
        border: none;
        outline: none;
        padding: 12px 15px;
        font-size: 18px;
    }
}

.btn {
    background-color: #27DED8;
    border: none;
    font-size: 14px;
    min-width: 250px;
    outline: none;
    border-radius: 3px;
    padding: 12px 15px;
    color: $color-white;
    font-weight: 700;
    display: inline-block;
    text-align: center;
    text-decoration: none;
    color: #13143C;
    cursor: pointer;

    &.btn-gray,
    &.btn-oragne,
    &.btn-purple,
    &.btn-pink,
    &.btn-sm,
    &.btn-simple {
        min-width: 125px;
        font-size: 12px;
        padding: 6px 15px;
        text-align: center;
        border-radius: 3px;
        font-weight: 400;
    }

    &.btn-sm {
        background-color: #27DED8;
        color: #13143C;
    }

    &.btn-pink {
        background-color: #DB2895;
        color: $color-white;

    }

    &.btn-simple {
        background-color: transparent;
        border: solid 1px $color-white;
        color: $color-white;
    }

    &.btn-purple {
        background: #7713D9 !important;
        color: $color-white
    }

    &.btn-gray {
        background: rgba($color-white, 0.5) !important;
        color: #252C34 !important;
        color: $color-white;
    }

    &.btn-oragne {
        background: $color-orange !important;
        color: $color-white !important;
        color: $color-white;
    }

}

.mb-0 {
    margin-bottom: 0 !important;
}

.mt-0 {
    margin-top: 0 !important;
}

.mt-1 {
    margin-top: 10px !important;
}

.mt-2 {
    margin-top: 20px !important;
}

.mt-3 {
    margin-top: 30px !important;
}

.mt-4 {
    margin-top: 40px !important;
}

.pt-0 {
    padding-top: 0 !important;
}


h1 {
    font-size: $font-size-h1;
    font-weight: 400;


}

h2 {
    font-size: $font-size-h2;
    font-weight: 400;


}

h3 {
    font-size: $font-size-h3;
}

h4 {
    font-size: $font-size-h4;
}

h5 {
    font-size: $font-size-h5;
}

h6 {
    font-size: $font-size-h6;
}

p {
    line-height: 1.5;
}

.emoji-mart-search {
    input {
        color: $color-black !important;

        &::-webkit-input-placeholder {
            color: rgba($color-black, 0.5) !important;
        }

        &::-moz-placeholder {
            color: rgba($color-black, 0.5) !important;
        }

        &:-ms-input-placeholder {
            color: rgba($color-black, 0.5) !important;
        }

        &::-ms-input-placeholder {
            color: rgba($color-black, 0.5) !important;
        }

        &::placeholder {
            color: rgba($color-black, 0.5) !important;
        }
    }
}


.success-message {
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: #13143C;
    color: $color-white;
    padding: 10px;
    border-radius: 3px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
    width: 400px;
    display: flex;
    align-items: center;
    transition: all 0.5s;
    opacity: 0;
    visibility: hidden;
    z-index: -1;

    .mobile-text {
        display: none;
    }

    @media (max-width: 767px) {
        width: 90%;

        .desktop-text {
            display: none;
        }

        .mobile-text {
            display: block;
        }
    }

    &.active {
        top: 30px;
        opacity: 1;
        visibility: visible;
        z-index: 999999999999;
    }

    // @media(max-width:1500px) {
    //     top: 70px;
    // }

    span {
        img {
            width: 20px;
            margin-right: 10px;
        }
    }

    p {
        font-size: 14px;
        display: inline-block;
        vertical-align: middle;

        @media(max-width:1500px) {

            font-size: 12px;
        }
    }

    button {
        background-color: transparent;
        border: none;
        outline: none;
        margin-left: auto;
        height: 12px;
    }
}


.form-message {
    p {

        color: #ff6363
    }

    &.success {
        p {
            color: #00b943;
        }
    }

    &.error {
        p {
            color: #ff6363
        }
    }
}
