@import "../../setting.scss";

.view-profile {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  background-color: #13143c;
  z-index: 9999;
  opacity: 0;
  left: 100%;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;

  @media (max-width: 767px) {
    position: fixed;
    z-index: 999999999;
    overflow: auto;
  }

  &.active {
    left: 0;
    opacity: 1;
  }

  &:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 139px;
    background: -webkit-gradient(
        linear,
        left top,
        right top,
        from(#7713d9),
        to(rgba(38, 231, 224, 0))
      ),
      #27ded8;
    background: linear-gradient(90deg, #7713d9 0%, rgba(38, 231, 224, 0) 100%),
      #27ded8;
    top: 0;
    left: 0;

    @media (max-width: 767px) {
      display: none;
    }
  }

  .popup-box {
    padding: 0px 100px;
    position: relative;
    z-index: 2;

    .back-btn {
      position: absolute;
      top: 20px;
      left: 20px;
      cursor: pointer;
    }

    @media (max-width: 1200px) {
      padding: 0px 50px;
    }

    @media (max-width: 767px) {
      padding: 0px 30px;
    }
  }

  .head-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-top: 40px;
    padding-bottom: 30px;

    @media (max-width: 767px) {
      flex-direction: column;
      align-items: center;
    }

    figure {
      width: 150px;
      height: 150px;
      border-radius: 50%;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        -o-object-fit: cover;
        object-fit: cover;
      }
    }

    .detail-box {
      padding-left: 25px;
      padding-top: 20px;
      -webkit-box-flex: 1;
      -ms-flex: 1;
      flex: 1;

      @media (max-width: 767px) {
        width: 100%;
        text-align: center;
        padding-left: 0;
      }

      h4 {
        font-size: 24px;
      }

      ul {
        li {
          list-style: none;
        }
      }

      .detail-group {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        margin-top: 15px;

        li {
          color: rgba($color-white, 0.6);
          display: inline-block;
          border-left: solid 1px rgba($color-white, 0.6);
          line-height: 1;
          padding: 0px 5px;

          @media (max-width: 1500px) {
            font-size: 14px;
          }

          &:first-child {
            border-left: none;
            padding-left: 0;
          }
        }

        .social-detail {
          li {
            border: none;
            padding: 0;
            width: 25px;
            height: 25px;
            line-height: 25px;
            background-color: #dadada;
            border-radius: 50%;
            text-align: center;
            margin-left: 30px;

            a {
              color: #1ac3be;
              font-size: 14px;
            }
          }
        }
      }

      .send-box {
        margin-top: 30px;
        width: 100%;
        position: relative;

        input {
          width: 100%;
          background-color: transparent;
          border: solid 1px rgba($color-white, 0.5) !important;
          border-radius: 3px;
          padding: 5px 15px;
          font-size: 14px;
          padding-right: 60px;

          @media (max-width: 1400px) {
            font-size: 12px;
          }

          &::-webkit-input-placeholder {
            color: rgba($color-white, 0.5);
          }

          &::-moz-placeholder {
            color: rgba($color-white, 0.5);
          }

          &:-ms-input-placeholder {
            color: rgba($color-white, 0.5);
          }

          &::-ms-input-placeholder {
            color: rgba($color-white, 0.5);
          }

          &::placeholder {
            color: rgba($color-white, 0.5);
          }
        }

        ul {
          position: absolute;
          top: 50%;
          right: 0;
          -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          z-index: 99999;

          li {
            color: rgba($color-white, 0.5);
            margin-right: 10px;
            position: relative;

            button {
              background-color: transparent;
              border: none;
              outline: none;
              color: rgba($color-white, 0.5);
            }

            i {
              cursor: pointer;
            }

            &:last-of-type {
              -webkit-box-ordinal-group: 0;
              -ms-flex-order: -1;
              order: -1;
            }

            .emoji-mart {
              position: absolute;
              right: 0;
              top: calc(100% + 5px);

              button {
                color: rgba(0, 0, 0, 0.5);
                cursor: pointer;

                span {
                  cursor: pointer;
                }
              }

              .emoji-mart-bar {
                display: none;
              }

              .emoji-mart-search {
                display: none;
              }

              ul {
                position: static;
                -webkit-transform: translate(0);
                transform: translate(0);
                display: block;
              }

              .emoji-mart-category-label {
                display: none;
              }

              .emoji-mart-scroll {
                height: 200px;
              }
            }
          }
        }
      }
    }
  }

  .body-section {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;

    .colmn-text,
    .colmn-img {
      width: calc(50% - 25px);
    }

    .colmn-text {
      span {
        color: rgba($color-white, 0.4);
        display: block;
        margin-bottom: 5px;

        @media (max-width: 1500px) {
          font-size: 14px;
        }
      }

      p {
        @media (max-width: 1500px) {
          font-size: 14px;
        }
      }

      .text-box {
        margin-bottom: 40px;

        @media (max-width: 1500px) {
          margin-bottom: 30px;
        }
      }
    }

    .colmn-img {
      span {
        color: rgba($color-white, 0.4);
        display: block;
        margin-bottom: 5px;
      }

      ul {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;

        li {
          width: calc(50% - 5px);
          list-style: none;

          figure {
            width: 100%;
            height: 85px;
          }
        }
      }
    }
  }
}
