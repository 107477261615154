.main-page {
    background-color: #13143C;
    padding: 25px 40px;

    @media (max-width: 1500px) {
        padding: 25px 20px;
    }

    .flex-wrapper {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        >div {
            margin-top: 40px;

            @media (max-width: 1500px) {
                margin-top: 20px;
            }

            @media (max-width: 992px) {
                margin-top: 0;
            }

            &:nth-of-type(1),
            &:nth-of-type(2) {
                margin-top: 0
            }
        }
    }

    .stream-box,
    .conversation-box,
    .whoHere-box,
    .feed-box {
        width: calc(50% - 20px);
        padding-top: 27.6%;
        position: relative;

        @media (max-width: 1400px) {
            width: calc(50% - 10px);
            padding-top: 27.8%;
        }

        @media (max-width: 992px) {
            width: 100%;
            margin-bottom: 20px;
            margin-top: 0;
            padding-top: 56.3%;
        }

        .main-box {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        @media (max-width: 1500px) {
            // width: calc(50% - 10px);
        }



    }

    .whoHere-box {
        @media (max-width: 767px) {
            padding-top: 0;
            height: 330px;
        }

    }

    .stream-box {
        @media (max-width: 992px) {
            order: -1;
        }
    }


    .conversation-box {
        .main-box {
            display: flex;
            overflow: hidden;
        }

        @media (max-width: 767px) {
            position: fixed;
            width: 100%;
            height: 70%;
            padding-top: 0;
            z-index: 999999999;
            left: 0;
            top: 100%;
            margin-bottom: 0;
            background-color: #381A5C;
            border-top-left-radius: 19px;
            border-top-right-radius: 19px;
            transition: all 0.5s;

            &.active {
                height: 100%;
                border-top-left-radius: 0;
                border-top-right-radius: 0;
            }

            &.open {
                transform: translateY(-100%);
            }

            .main-box {
                position: relative;
                top: unset;
                left: unset;

            }
        }
    }

    .feed-box,
    .whoHere-box {
        margin-top: 40px;
    }
}
