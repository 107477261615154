@import "../../setting.scss";

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($color-black, 0.7);
  transition: all 0.5s;
  z-index: 9999999999999999;

  &.sign-out {
    &.without-email {
      .popup-box {
        width: 420px;
      }
    }

    .btn-close {
      display: none;
    }

    .popup-box {
      width: 320px;
      text-align: center;
      padding: 50px 60px 40px;
      background: linear-gradient(180deg, #37195e 0%, rgba(66, 29, 102, 0) 100%),
        #361a50;
      box-shadow: 0px 0px 29px #000000;
      border-radius: 10px;

      h2 {
        font-size: 18px;
        margin-bottom: 15px;

        img {
          width: 10px;
          filter: invert(1);
          width: 17px;
          vertical-align: middle;
          margin-bottom: 4px;
        }
      }

      p {
        font-size: 14px;
        opacity: 0.8;
        margin-bottom: 20px;
      }

      ul {
        li {
          list-style: none;
          margin-bottom: 15px;

          &:last-of-type {
            margin-bottom: 0;
          }

          .btn-pink,
          .btn-outline {
            min-width: 170px;
            font-size: 14px;
            padding: 8px 15px;
          }

          .btn-outline {
            color: #fff;
            border: solid 1px #fff;
            background-color: transparent;
          }

          .btn-simple {
            border: none;
            padding-top: 0;
          }
        }
      }
    }
  }

  &.file-popup {
    text-align: center;

    .btn-close {
      top: 0;
      right: 0;
      width: 40px;
      height: 40px;
      transform: translate(50%, -50%);
      background: #5f306b;
      box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.8);
      border: none;

      i {
        font-size: 25px;
        padding-top: 0;
      }
    }

    .popup-box {
      width: 500px;
      background: linear-gradient(217.4deg, #db2895 10.49%, #843598 61.25%),
        #843598;

      @media (max-width: 767px) {
        width: calc(100% - 40px);

        .btn-close {
          width: 26px;
          height: 26px;
        }
      }

      .box {
        padding: 30px;
      }

      .file-uploaded {
        figure {
          width: 67px;
          height: 67px;
          border-radius: 15px;
          background-color: rgba($color-white, 0.3);
          transform: rotate(45deg);
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 auto 30px;
          img {
            width: 30px;
            transform: rotate(-45deg);
            max-height: 40px;
          }
        }

        h6 {
          font-size: 20px;
          margin-bottom: 5px;
        }

        p {
          font-size: 14px;
          font-weight: 700;
          margin-bottom: 20px;
        }
      }

      .image-preview {
        position: relative;
        margin-top: -15px;

        .btn-back {
          position: absolute;
          top: 0;
          left: 0;
        }
        .image-list {
          display: flex;
          justify-content: center;
          flex-wrap: wrap;

          .multi {
            margin: 0px 5px 10px;
            width: 100px;
            height: 70px;
          }
        }

        .fail-detail {
          display: flex;
          margin-right: auto;
          align-items: center;

          p {
            color: #eb5757 !important;
            margin-right: 0;
            margin-left: 5px;
          }
        }

        .file-loader {
          margin-right: auto;
          display: flex;
          align-items: center;

          img {
            width: 20px;
          }

          p {
            display: inline-block;
            margin-left: 5px;
            font-weight: 700;
          }
        }

        h6 {
          font-size: 14px;
          color: rgba($color-white, 0.8);
          font-weight: 400;
          margin-bottom: 20px;
        }

        figure {
          width: 180px;
          margin: 0 auto 20px;
          height: 97px;

          img {
            width: 100%;
            height: 100%;
            -o-object-fit: cover;
            object-fit: cover;
            @media (max-width: 767px) {
              object-fit: contain;
            }
          }
        }

        .form-group {
          margin-bottom: 5px;

          textarea {
            width: 100%;
            height: 87px;
            border-radius: 3px;
            background-color: rgba($color-white, 0.2);
            padding: 10px;
            font-size: 14px;
            resize: none;
          }
        }

        .btn-box {
          display: flex;
          align-items: center;
          justify-content: flex-end;

          p {
            font-size: 14px;
            color: rgba($color-white, 0.5);
            margin-right: 10px;
          }
        }
      }

      .uploaded-box {
        padding: 20px;

        .btn {
          margin-top: 20px;
        }
      }

      .file-uploadBox {
        width: 100%;
        height: 325px;
        border: dashed 1px rgba($color-white, 0.5);
        position: relative;

        @media (max-width: 767px) {
          height: 150px;
        }

        input {
          position: absolute;
          top: 0%;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 9;
          opacity: 0;
        }

        .upload-placeholder {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);

          img {
            width: 60px;
          }

          p {
            white-space: nowrap;
            margin-top: 30px;
          }
        }

        .desktop-group {
          @media (max-width: 767px) {
            display: none;
          }
        }

        .mobile-group {
          display: none;

          @media (max-width: 767px) {
            position: absolute;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            button {
              background: #1db4af;
              border: none;
              outline: none;
              color: #fff;
              display: flex;
              align-items: center;
              padding: 10px 15px;
              font-size: 14px;

              img {
                width: 20px;
                margin-right: 5px;
              }
            }
          }
        }
      }
    }

    .box {
      padding: 50px;

      h3 {
        font-size: 24px;
        font-weight: 400;
      }

      ul {
        margin-top: 30px;

        li {
          margin: 0px 10px;
          list-style: none;
          display: inline-block;
        }
      }

      .btn {
        position: relative;
        overflow: hidden;

        input {
          position: absolute;
          width: 100%;
          height: 100%;
          opacity: 0;
          z-index: 9;
          top: 0;
          left: 0;
        }
      }
    }
  }

  .backDrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
  }

  .popup-box {
    background-color: #13143c;
    border-radius: 6px;

    width: 570px;
    position: absolute;
    z-index: 99999;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .btn-close {
    width: 22px;
    height: 22px;
    border: solid 1px $color-white;
    @extend %flexCenter;
    position: absolute;
    top: 24px;
    right: 26px;
    border-radius: 50%;
    transition: all 0.5s;
    cursor: pointer;

    i {
      color: $color-white;
      padding-top: 1px;
    }

    &:hover {
      background-color: $color-orange;
      border-color: $color-orange;
    }
  }

  .profile-detail {
    padding: 40px;
    padding-right: 100px;

    p {
      font-size: 14px;
    }

    .btn-box {
      display: flex;
      justify-content: space-between;

      li {
        width: calc(50% - 5px);
        cursor: pointer;
      }
    }

    .user-info {
      display: flex;
      align-items: center;
      margin-bottom: 35px;

      figure {
        width: 130px;
        height: 130px;

        img {
          border-radius: 50%;
          width: 100%;
          height: 100%;
          -o-object-fit: cover;
          object-fit: cover;
        }
      }

      .detail-box {
        flex: 1;
        padding-left: 35px;

        h2 {
          margin-bottom: 5px;
        }
      }
    }

    li {
      list-style: none;
      font-size: 14px;
      color: rgba($color-white, 0.6);
      display: inline-block;
      border-left: solid 1px rgba($color-white, 0.6);
      padding: 0px 5px;
      line-height: 1;

      &:first-of-type {
        border-left: 0;
      }
    }
  }
}

.send-message {
  margin-right: -50px;
  position: relative;

  input {
    background: rgba(196, 196, 196, 0.2);
    border-radius: 3px;
    color: $color-white;
    border: none;
    outline: none;
    width: 100%;
    padding: 8px 10px;
    font-size: 14px;
    padding-right: 60px;
  }

  ul {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 5px;

    li {
      display: inline-block;
      list-style: none;
      color: rgba($color-white, 0.6);
      margin: 0 5px;

      button {
        background-color: transparent;
        border: none;
        color: rgba($color-white, 0.6);
      }
    }
  }
}

.btn-list {
  display: flex;
  justify-content: space-between;

  li {
    display: inline-block;
    width: calc(50% - 5px);

    button {
      background-color: rgba($color-white, 0.5);
      color: $color-black;
      font-size: 14px;
      border-radius: 3px;
      width: 100%;
      display: inline-block;
      border: none;
      padding: 8px 15px;
    }
  }
}

.text-group,
.social-detail {
  margin-top: 35px;

  h6 {
    color: rgba($color-white, 0.4);
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 400;
  }
}

.social-detail {
  h6 {
    margin-bottom: 20px;
  }

  ul {
    display: flex;

    li {
      display: inline-block;
      width: 25%;

      figure {
        width: 30px;
        height: 30px;
        background-color: #dadada;
        border-radius: 50%;
        @extend %flexCenter;
        margin: 0 auto 10px;

        i {
          color: $color-black;
        }
      }

      a {
        text-decoration: none;
        color: $color-white;
      }

      p {
        text-align: center;
        font-size: 14px;
      }
    }
  }
}

.invalid-popup {
  .btn-close {
    display: none;
  }
  .popup-box {
    min-height: 100px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px;
  }
}
