@import '../../setting.scss';

.popup {
    &.detail-popup {
        .btn-close {
            border: none;
            top: 15px;
            right: 15px;

            i {
                font-size: 30px;
            }

            &:hover {
                background-color: transparent;
            }
        }

        .popup-box {
            background-color: #13143C;
            width: 900px;
            z-index: 1;
            border-radius: 6px;

            &:after {
                background-color: rgba($color-white, 0.1);
                width: 100%;
                height: 100%;
                content: "";
                z-index: -1;
                top: 0;
                left: 0;
                position: absolute;
            }

            .tab-btn {
                width: 100%;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                padding: 0px 20px;
                border-bottom: solid 1px rgba($color-white, 0.1);




                li {
                    list-style: none;
                    padding: 0 0 25px;
                    margin-top: 35px;
                    -webkit-box-flex: 1;
                    -ms-flex: 1;
                    flex: 1;
                    text-align: center;
                    border-bottom: solid 1px transparent;
                    cursor: pointer;

                    @media(max-width:1500px) {
                        padding: 0 0 15px;
                        margin-top: 15px;
                        font-size: 14px;


                    }

                    &.active {
                        border-bottom: solid 1px #27DED8;
                        color: #27DED8;
                    }

                    a {
                        color: rgba($color-white, 0.5);
                        font-size: 18px;
                        list-style: none;
                        text-decoration: none;



                    }

                }
            }
        }

        .tab-group {
            padding: 25px 45px;

            @media(max-width:1700px) {
                padding: 25px 20px;
            }

            @media (max-width:1200px) {
                padding: 20px 15px;
            }

            .faq-box {
                padding: 20px 0px;

                .box {
                    margin-bottom: 25px;
                }

                span {
                    color: $color-white;
                    font-size: 14px;
                    display: block;
                    margin-bottom: 5px;
                }

                p {
                    color: #959595;
                    font-size: 14px;
                }
            }

            .artist-message {
                padding-bottom: 50px;

                .testDetail-box {
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                    -webkit-box-pack: justify;
                    -ms-flex-pack: justify;
                    justify-content: space-between;
                    margin-bottom: 15px;

                    h2 {
                        margin-bottom: 0 !important
                    }

                    .detail-box {
                        margin-top: 0;
                    }
                }

                .box-head {
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-pack: justify;
                    -ms-flex-pack: justify;
                    justify-content: space-between;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                    margin-bottom: 30px;

                    h5 {
                        font-size: 16px;
                    }

                    ul {
                        li {
                            display: inline-block;
                            color: #959595;
                            padding: 0px 15px;
                            vertical-align: middle;

                            &:last-of-type {
                                padding-right: 0;
                                border-left: solid 1px #959595;
                            }
                        }
                    }
                }

                .detail-box {
                    margin-top: 30px;

                    span {
                        font-size: 14px;
                        color: rgba($color-white, 0.4);
                        display: block;
                        margin-bottom: 10px;
                    }

                    p {
                        font-size: 14px;
                        color: $color-white;
                    }

                    ul {
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;
                        // margin-top: 15px;

                        li {
                            display: inline-block;
                            margin-left: 20px;

                            figure {
                                width: 30px;
                                height: 30px;
                                background-color: #DADADA;
                                border-radius: 50%;
                                @extend %flexCenter;
                                margin: 0 auto;

                                i {
                                    color: $color-black;
                                }
                            }

                            a {
                                text-decoration: none;
                                color: $color-white;
                            }

                            p {
                                text-align: center;
                                font-size: 14px;
                            }
                        }
                    }
                }

                .user-box {
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;

                    figure {
                        width: 130px;
                        height: 130px;
                        border-radius: 50%;
                        overflow: hidden;
                        display: inline-block;
                        margin-right: 30px;

                        img {
                            width: 100%;
                            height: 100%;
                            -o-object-fit: cover;
                            object-fit: cover;
                        }
                    }


                    .test-box {
                        -webkit-box-flex: 1;
                        -ms-flex: 1;
                        flex: 1;

                        h2 {
                            font-size: 32px;
                            margin-bottom: 10px;
                        }

                        textarea {
                            width: 100%;
                            height: 60px;
                            background-color: transparent;
                            border: none;
                            outline: none;
                            font-size: 14px;
                            resize: none;
                            padding: 10px;
                            color: $color-white;
                            border-radius: 3px;
                            border: solid 1px $color-white !important
                        }

                        .form-group {
                            position: relative;

                            ul {
                                position: absolute;
                                bottom: 6px;
                                right: 3px;
                                background: #2c2b50;
                                padding: 5px 5px;
                                border-bottom-right-radius: 3px;

                                li {
                                    list-style: none;
                                    display: inline-block;
                                    padding: 0px 5px;

                                    button {
                                        background-color: transparent;
                                        outline: none;
                                        border: none;
                                        color: rgba($color-white, 0.6);
                                    }
                                }
                            }
                        }


                    }
                }
            }

            .donate-box {
                max-width: 500px;

                p {
                    font-size: 14px;

                }

                .form-box {
                    margin-top: 30px;

                    label {
                        display: block;
                        font-weight: 700;
                        margin-bottom: 10px;
                        font-size: 14px;

                        span {
                            font-weight: 400;
                            color: #959595;
                            font-size: 12px;
                            display: inline-block;
                            margin-left: 5px;
                        }
                    }

                    .form-group {
                        margin-bottom: 30px;

                        ul {
                            li {
                                display: inline-block;
                                margin-right: 7px;
                                cursor: pointer;

                                span {
                                    text-align: center;
                                    background-color: rgba(196, 196, 196, 0.2);
                                    width: 63px;
                                    height: 47px;
                                    border-radius: 3px;
                                    display: -webkit-box;
                                    display: -ms-flexbox;
                                    display: flex;
                                    -webkit-box-align: center;
                                    -ms-flex-align: center;
                                    align-items: center;
                                    -webkit-box-pack: center;
                                    -ms-flex-pack: center;
                                    justify-content: center;
                                    font-size: 14px;
                                }

                                input {
                                    background-color: rgba(196, 196, 196, 0.2);
                                    width: 63px;
                                    height: 47px;
                                    border-radius: 3px;
                                    display: -webkit-box;
                                    display: -ms-flexbox;
                                    text-align: center;
                                    border: none;
                                    outline: none;
                                    color: $color-white;
                                    font-size: 14px;

                                }
                            }
                        }

                        textarea {
                            width: 100%;
                            background-color: rgba(196, 196, 196, 0.2);
                            height: 100px;
                            border-radius: 3px;
                            font-size: 14px;
                            border: none;
                            resize: none;
                            color: $color-white;
                            padding: 15px;

                            &::-webkit-input-placeholder {
                                color: rgba(255, 255, 255, 0.3);
                                ;
                            }

                            &::-moz-placeholder {
                                color: rgba(255, 255, 255, 0.3);
                                ;
                            }

                            &:-ms-input-placeholder {
                                color: rgba(255, 255, 255, 0.3);
                                ;
                            }

                            &::-ms-input-placeholder {
                                color: rgba(255, 255, 255, 0.3);
                                ;
                            }

                            &::placeholder {
                                color: rgba(255, 255, 255, 0.3);
                                ;
                            }
                        }
                    }
                }
            }

            .about-box {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;

                .icon-box {
                    width: 70px;

                    figure {
                        width: 45px;
                        height: 45px;
                        border-radius: 50%;
                        overflow: hidden;

                        img {
                            width: 100%;
                            height: 100%;
                            -o-object-fit: cover;
                            object-fit: cover;
                        }
                    }
                }

                .content-section {
                    -webkit-box-flex: 1;
                    -ms-flex: 1;
                    flex: 1;
                    padding-bottom: 50px;

                    h5 {
                        font-size: 16px;
                    }

                    span {
                        font-size: 16px;
                        color: #959595;
                        display: block;
                        margin-bottom: 15px;
                    }

                    p {
                        margin-bottom: 20px;
                    }
                }
            }

            .tab-head {
                p {
                    color: rgba($color-white, 0.5)
                }

                .search-box {
                    position: relative;



                    input {
                        background-color: rgba(196, 196, 196, 0.2);
                        border: none;
                        font-size: 16px;
                        padding: 10px 40px;
                        outline: none;
                        width: 350px;
                        border-radius: 4px;

                        @media(max-width:1700px) {
                            width: 290px
                        }

                        @media(max-width:1600px) {
                            width: 230px
                        }

                        @media(max-width:1500px) {
                            width: 250px;
                            font-size: 14px;
                        }



                        &::-webkit-input-placeholder {
                            font-style: italic;
                        }

                        &::-moz-placeholder {
                            font-style: italic;
                        }

                        &:-ms-input-placeholder {
                            font-style: italic;
                        }

                        &::-ms-input-placeholder {
                            font-style: italic;
                        }

                        &::placeholder {
                            font-style: italic;
                        }
                    }

                    .search-icon,
                    .close-icon {
                        position: absolute;
                        top: 50%;
                        -webkit-transform: translateY(-50%);
                        transform: translateY(-50%);
                    }

                    .search-icon {
                        left: 10px;
                    }

                    .close-icon {
                        right: 10px;
                    }
                }
            }

            .user-listing {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -ms-flex-wrap: wrap;
                flex-wrap: wrap;
                // padding-top: 30px;

                .colmn-box {
                    width: calc(16.33% - 41px);
                    text-align: center;
                    margin-top: 20px;
                    margin-right: 50px;
                    cursor: pointer;

                    &:nth-of-type(6n) {
                        margin-right: 0;

                        @media(max-width:1600px) {
                            margin-right: 2%;
                        }
                    }


                    @media(max-width:1700px) {
                        margin-right: 45px;
                    }

                    @media(max-width:1600px) {
                        width: 16.666%;
                        margin-right: 0;

                        &:nth-of-type(6n) {
                            margin-right: 0;
                        }
                    }

                    @media (max-width: 1200px) {
                        width: 25%;
                        margin-top: 10px;
                    }

                    figure {
                        width: 90px;
                        height: 90px;
                        border-radius: 50%;
                        overflow: auto;
                        padding: 2.5px;
                        margin: 0 auto;

                        @media(max-width:1700px) {
                            width: 70px;
                            height: 70px;
                        }

                        @media(max-width:1600px) {
                            width: 70px;
                            height: 70px;
                        }

                        @media (max-width:1200px) {
                            width: 60px;
                            height: 60px;
                        }

                        &:after {
                            width: 100%;
                            height: 100%;
                            z-index: 1;
                            left: 50%;
                            top: 50%;
                            -webkit-transform: translate(-50%, -50%);
                            transform: translate(-50%, -50%);
                        }

                        img {
                            width: 100%;
                            height: 100%;
                            -o-object-fit: cover;
                            object-fit: cover;
                            border-radius: 50%;
                            border: solid 2.5px $color-grayPrimary;
                            position: relative;
                            z-index: 2;

                        }
                    }

                    p {
                        font-size: 14px;
                        margin-top: 10px;
                        overflow: hidden;
                        width: 70px;
                        text-align: center;
                        margin: 10px auto 0;
                        text-overflow: ellipsis;

                        @media (max-width:1200px) {
                            margin-top: 5px;
                            font-size: 12px;
                        }
                    }
                }
            }
        }
    }
}
