@import "../../setting.scss";

.conversation-box {
    overflow: hidden;
    display: flex;
    position: relative;


    .main-box {
        display: flex;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .user-list {
        width: 27%;
        background: linear-gradient(180deg, #37195e 0%, rgba(66, 29, 102, 0) 100%),
            #361a50;
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;

        @media (max-width: 1500px) {
            width: 35%;
        }

        @media (max-width: 767px) {
            width: 100%;
        }

        .head-box {
            border-bottom: 1px solid rgba($color-white, 0.1);
            text-align: center;
            padding: 20px 0 17px;
            height: 55px;

            @media (max-width: 1500px) {
                height: 45px;
                padding: 15px 0;
            }

            button {
                display: none;
            }


            @media (max-width: 767px) {
                height: unset;
                padding: 15px 15px;
                ;
                position: relative;

                button {
                    display: inline-block;
                    background-color: transparent;
                    color: #fff;
                    border: none;
                    outline: none;
                    height: unset;
                    width: 30px;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    right: 10px;

                    img {
                        vertical-align: middle;
                    }

                }
            }

            h3 {
                font-size: 14px;
                text-transform: capitalize;
            }

            i {
                cursor: pointer;
            }
        }

        ul {
            overflow: auto;
            height: calc(100% - 55px);

            li {
                list-style: none;
                padding: 12px 17px;

                @media (max-width: 1500px) {
                    padding: 10px 5px;
                }

                @media (max-width: 767px) {
                    padding: 10px 15px;
                }

                &.active {
                    background-color: rgba(19, 20, 60, 0.6);
                }
            }
        }

        .detail-box {
            display: flex;
            cursor: pointer;

            figure {
                width: 32px;
                height: 32px;
                border-radius: 50%;
                overflow: hidden;
                margin-right: 12px;

                img {
                    width: 100%;
                    height: 100%;
                    -o-object-fit: cover;
                    object-fit: cover;
                }
            }

            h4 {
                font-size: 14px;

                @media (max-width: 1500px) {
                    font-size: 12px;
                }
            }

            .text-box {
                position: relative;
                flex: 1;
                width: calc(100% - 45px);
            }

            span {
                font-size: 12px;
                opacity: 0.5;
                position: absolute;
                top: 0;
                right: 0;

                &.unseen-msg {
                    bottom: 0;
                    right: 0;
                    width: 16px;
                    height: 16px;
                    text-align: center;
                    display: inline-block;
                    border-radius: 50%;
                    line-height: 16px;
                    background-color: #1db4af;
                    opacity: 1;
                    top: auto;
                }
            }

            p {
                font-size: 12px;
                font-weight: 700;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }
        }
    }

    .chat-box {
        width: 73%;
        transition: all 0.5s;
        display: none;

        &.open {
            display: block;
        }


        @media (max-width: 1500px) {
            width: 65%;
        }

        @media (max-width: 767px) {
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            left: 100%;
            top: 0;
            transition: all 0.5s;
            background-color: #13143C;

            &.open {
                left: 0;
            }
        }

        &.active {
            width: calc(73% - 240px);

            @media (max-width: 1500px) {
                width: 65%;
            }
        }

        .chat-header {
            background-color: rgba($color-white, 0.06);
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 11px 20px;
            border-top-right-radius: 6px;
            border-bottom: 1px solid rgba(118, 19, 217, 0.5);
            height: 55px;

            @media (max-width: 1500px) {
                height: 45px;
                padding: 5px 15px;
            }

            .back-btn {
                display: none;
            }

            @media (max-width: 767px) {
                justify-content: flex-start;

                ul {
                    display: none !important;
                }

                .back-btn {
                    display: inline-block;
                    background-color: transparent;
                    border: none;
                    outline: none;
                    margin-right: 10px;

                    img {
                        vertical-align: middle;
                    }
                }
            }

            .detail-box {
                display: flex;
                align-items: center;
                cursor: pointer;

                figure {
                    width: 28px;
                    height: 28px;
                    overflow: hidden;
                    border-radius: 50%;
                    margin-right: 10px;

                    img {
                        width: 100%;
                        height: 100%;
                        -o-object-fit: cover;
                        object-fit: cover;
                    }
                }

                h4 {
                    font-size: 14px;
                    margin-bottom: -2px;
                }

                p {
                    font-size: 12px;
                    color: rgba($color-white, 0.6);
                }
            }

            ul {
                display: flex;

                li {
                    display: inline-block;
                    margin-left: 15px;
                    cursor: pointer;
                    display: flex;
                    align-items: center;

                    img {
                        vertical-align: middle;
                        display: inline-block;
                        margin-right: 5px;
                    }

                    p {
                        font-size: 16px;
                    }
                }
            }
        }

        .chat-summary {
            padding: 15px 15px 0;
            padding-right: 30px;
            height: calc(100% - 105px);
            overflow: auto;
            background-color: rgba(255, 255, 255, 0.04);

            @media (max-width: 1500px) {
                height: calc(100% - 85px);
            }

            ul {
                li {
                    list-style: none;
                    margin-bottom: 12px;

                    &.my-message {
                        text-align: right;

                        .detail-box {
                            justify-content: flex-end;

                            h4 {
                                display: none;
                            }

                            figure {
                                margin-right: 0;
                                margin-left: 15px;
                            }

                            .text-box {
                                order: -1;
                                border-radius: 10px 10px 0px 10px;

                                @media (max-width: 1500px) {
                                    border-radius: 5px 5px 0px 5px;
                                }

                                &:after {
                                    right: auto;
                                    left: 100%;
                                    border-left: none;
                                    border-right: solid 12px transparent;

                                }

                                &:before {
                                    right: auto;
                                    left: 100%;
                                }
                            }
                        }
                    }
                }
            }

            .detail-box {
                display: flex;
                align-items: flex-end;

                figure {
                    width: 33px;
                    height: 33px;
                    border-radius: 50%;
                    overflow: hidden;
                    margin-right: 15px;
                    position: relative;
                    z-index: 99;

                    img {
                        width: 100%;
                        height: 100%;
                        -o-object-fit: cover;
                        object-fit: cover;
                    }
                }

                .text-box {
                    border-radius: 10px 10px 10px 0;
                    background-color: rgba($color-white, 0.08);
                    padding-right: 80px;
                    padding-left: 15px;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    position: relative;
                    max-width: calc(100% - 50px);

                    @media (max-width: 1500px) {
                        padding-top: 5px;
                        padding-bottom: 5px;
                        padding-left: 10px;
                        border-radius: 5px 5px 5px 0;
                    }

                    h4 {
                        font-size: 14px;
                    }

                    p,
                    a {
                        font-size: 15px;
                        width: 100%;
                        // overflow: hidden;
                        // text-overflow: ellipsis;
                        text-decoration: none;
                        color: #fff;
                        display: inline-block;
                        overflow-wrap: break-word;
                        word-wrap: break-word;


                    }

                    p {
                        position: relative;

                        p {
                            display: inline-block;
                        }

                        i {
                            position: absolute;
                            right: 0;
                            top: 50%;
                            transform: translateY(-50%);
                            opacity: 1;
                            color: #cc0010;
                            // background-color: #fff;
                            // border-radius: 50%;


                        }

                    }

                    a {
                        &.internal-link {
                            font-weight: 700;
                            font-style: italic;
                        }


                        &:hover {
                            text-decoration: underline;
                        }
                    }

                    span {
                        position: absolute;
                        bottom: 13px;
                        right: 15px;
                        opacity: 0.5;
                        font-size: 12px;

                        @media (max-width: 1500px) {
                            bottom: 7px;
                            right: 10px;
                        }
                    }

                    &:after {
                        position: absolute;
                        border-bottom: solid 15px rgba($color-white, 0.08);
                        border-top: solid 12px transparent;
                        border-left: solid 12px transparent;
                        content: "";
                        bottom: 0;
                        right: 100%;
                    }

                    &:before {
                        width: 32px;
                        height: 32px;
                        border-radius: 50%;
                        content: "";
                        bottom: 0;
                        right: 100%;
                        position: absolute;
                        z-index: 9;
                        background-color: #1c1d44;
                    }
                }
            }
        }

        .send-message {
            width: 100%;
            position: relative;

            input {
                width: 100%;
                background-color: rgba($color-white, 0.08);
                padding: 13px 20px;
                border: none;
                outline: none;
                border-bottom-right-radius: 6px;
                padding-right: 75px;

                @media (max-width: 1500px) {
                    font-size: 14px;
                    padding-top: 10px;
                    padding-bottom: 10px;
                }
            }

            .login-btn-group {
                li {
                    &:first-of-type {
                        margin-right: 10px;
                    }
                }
            }

            .login-btn,
            .signUp-btn {
                padding: 3px 15px;
            }

            ul {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 0;
                z-index: 999;

                &.btn-list {
                    display: flex;

                    >li:last-of-type {
                        order: -1;
                    }
                }

                li {
                    margin-right: 15px;
                    list-style: none;
                    display: inline-block;
                    position: relative;
                    cursor: pointer;

                    button {
                        background-color: transparent;
                        border: none;
                        outline: none;
                        color: $color-white;
                        cursor: pointer;
                    }

                    .emoji-mart {
                        position: absolute;
                        bottom: calc(100% + 10px);
                        right: -40px;
                        z-index: 999;

                        .emoji-mart-preview {
                            display: none;
                        }

                        button {
                            color: $color-black;
                        }

                        .emoji-mart-search {
                            display: none;
                        }

                        ul {
                            position: static;
                            top: unset;
                            transform: translate(0, 0);

                            li {
                                color: $color-black;
                                margin-right: 0;

                                button {
                                    color: $color-black;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .group-detail {
        width: 240px;
        position: absolute;
        // width: 100%;
        height: 100%;
        left: 100%;
        top: 0;
        border-right: solid 1px transparent;
        background-color: rgba($color-white, 0.06);
        transform: translateX(0);
        opacity: 0;
        transition: all 0.5s;
        background-color: rgb(38, 38, 75);
        z-index: 999;

        @media (max-width: 1500px) {
            width: 200px;
        }

        @media (max-width: 767px) {
            width: 100%;
        }

        &.active {
            transform: translateX(-100%);
            opacity: 1;
        }

        .detail-box {
            padding-top: 20px;
            overflow: auto;
            height: calc(100% - 55px);
        }

        .head-box {
            height: 55px;
            display: flex;
            align-items: center;
            padding: 0px 15px;
            border-bottom: 1px solid rgba(118, 19, 217, 0.5);

            button {
                display: none;
            }

            @media (max-width: 1500px) {
                height: 45px;
            }

            @media (max-width: 767px) {
                justify-content: space-between;

                button {
                    display: inline-block;
                    background-color: transparent;
                    color: #fff;
                    border: none;
                    outline: none;
                }
            }

            i {
                cursor: pointer;
            }

            h4 {
                text-align: center;
                font-size: 14px;
                margin-left: auto;
                margin-right: auto;
            }
        }

        .group-box {
            text-align: center;
            margin-bottom: 30px;

            @media (max-width: 1500px) {
                margin-bottom: 20px;
            }

            figure {
                width: 152px;
                height: 152px;
                border-radius: 50%;
                overflow: hidden;
                margin: 0px auto 15px;

                @media (max-width: 1500px) {
                    width: 120px;
                    height: 120px;
                    margin-bottom: 10px;
                }

                img {
                    width: 100%;
                    height: 100%;
                    -o-object-fit: cover;
                    object-fit: cover;
                }
            }

            h3 {
                font-size: 24px;
            }

            span {
                font-size: 14px;
                color: rgba($color-white, 0.6);
            }
        }

        .user-listing {
            font-size: 0;

            li {
                width: 50%;
                display: inline-block;
                text-align: center;
                cursor: pointer;

                figure {
                    cursor: pointer;
                    width: 40px;
                    height: 40px;
                    margin: 0 auto;

                    img {
                        width: 100%;
                        height: 100%;
                        border-radius: 50%;
                        -o-object-fit: cover;
                        object-fit: cover;
                    }
                }

                p {
                    font-size: 14px;
                    margin-top: 10px;
                    margin-bottom: 15px;
                    padding: 0px 5px;
                    width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;

                    @media (max-width: 1500px) {
                        margin-top: 5px;
                    }
                }
            }
        }
    }
}
