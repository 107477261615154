@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;0,900;1,700&display=swap');
$color-primary: #3C444C;
$color-pink: #ED3CF0;
$color-orange: #F27A54;
$color-grayPrimary: #3C444C;
$color-grayDarker: #252C34;
$color-white: #fff;
$color-black: #000;
$color-background:#30363D;
$font-size-body: 18px;
$font-family-body: 'Lato',
sans-serif;

$container: 1170px !default;

$font-size-h1: 45px !default;
$font-size-h2: 32px !default;
$font-size-h3: 35px !default;
$font-size-h4: 30px !default;
$font-size-h5: 20px !default;
$font-size-h6: 17px !default;


%gradient {
    background: linear-gradient(90deg, #F27A54 0%, #A154F2 100%);
}

%flexCenter {
    display: flex;
    align-items: center;
    justify-content: center;
}


