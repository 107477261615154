@import '../../setting.scss';

.profile-layout {
    min-height: 100vh;
    padding-top: 50px;
    padding-bottom: 50px;
    background: #13143C;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;

    .container {
        width: 900px;
        max-width: 900px;
        background: rgba($color-white, 0.1);
        margin: 0 auto;
        border-radius: 6px;
        padding: 0;
    }
}
