@import "../../setting.scss";

header {
  background: -webkit-gradient(
      linear,
      left top,
      right top,
      color-stop(0.86%, #27ded8),
      color-stop(100.94%, rgba(255, 255, 255, 0))
    ),
    #7713d9;
  background: linear-gradient(
      90deg,
      #27ded8 0.86%,
      rgba(255, 255, 255, 0) 100.94%
    ),
    #7713d9;
  padding: 9px 40px;
  position: relative;
  z-index: 999999999;
  @media (max-width: 992px) {
    padding: 9px 20px;
  }

  .nothing {
    visibility: hidden;

    @media (max-width: 992px) {
      display: none;
    }
  }

  .event-title {
    p {
      color: #13143c;
      font-weight: 600;
    }

    @media (max-width: 992px) {
      margin-right: auto;

      p {
        color: #fff;
      }
    }
  }

  .back-btn {
    background-color: transparent;
    border: none;
    outline: none;
    color: #4d2472;
    cursor: pointer;

    img {
      vertical-align: middle;
      margin-right: 10px;
    }
  }

  &.map-header {
    .detail-btn {
      display: none;
    }

    .event-title {
      margin-left: auto;
      margin-right: auto;
    }
  }

  .navbar {
    li {
      display: inline-block;
      list-style: none;
      vertical-align: middle;
      margin-left: 15px;
      position: relative;
      cursor: pointer;

      button {
        cursor: pointer;
      }

      @media (max-width: 992px) {
        display: none;

        &.user-options {
          display: inline-block;
        }
      }

      .detail-btn {
        background-color: $color-white;
        color: #7713d9;
        padding: 4px 15px;
        outline: none;
        border: none;
        border-radius: 40px;
        font-weight: 700;
        font-size: 14px;
      }

      .edit-btn {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background-color: #fff;
        color: #7713d9;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        outline: none;
        cursor: pointer;
      }

      span {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background-color: $color-white;

        i {
          color: #7713d9;
          font-size: 14px;
          margin-bottom: -2px;
        }

        figure {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
            -o-object-fit: cover;
            object-fit: cover;
          }
        }
      }

      .dropdown {
        position: absolute;
        top: calc(100% + 10px);
        background: -webkit-gradient(
            linear,
            left top,
            left bottom,
            from(#37195e),
            to(rgba(66, 29, 102, 0))
          ),
          #361a50;
        background: linear-gradient(
            180deg,
            #37195e 0%,
            rgba(66, 29, 102, 0) 100%
          ),
          #361a50;
        -webkit-box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
        right: -20px;
        border-radius: 6px;
        width: 300px;
        z-index: 9999;

        @media (max-width: 767px) {
          width: 100vw;
        }

        &.user-dropdown {
          padding-bottom: 20px;

          @media (max-width: 767px) {
            padding-bottom: 10px;
          }

          li {
            padding: 5px 30px;
            font-size: 14px;
            cursor: pointer;

            &:first-of-type {
              border-bottom: solid 1px rgba($color-white, 0.1);
            }

            @media (max-width: 992px) {
              display: block;
            }
          }
        }

        &:after {
          border-bottom: solid 6px #37195e;
          border-left: solid 6px transparent;
          border-right: solid 6px transparent;
          content: "";
          position: absolute;
          bottom: 100%;
          right: 25px;
        }

        ul {
          position: relative;
          z-index: 999;
        }

        li {
          margin-left: 0;
          width: 100%;

          &.create-link {
            padding: 18px 30px;
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);
            margin-bottom: 10px;

            @media (max-width: 767px) {
              padding: 15px 30px;
              margin-bottom: 7px;
            }

            a {
              color: inherit;
              text-decoration: none;
            }
          }
        }

        .user-box {
          padding: 20px 0;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;

          @media (max-width: 767px) {
            padding: 10px 0;
          }

          figure {
            width: 60px;
            height: 60px;
            border-radius: 50%;
            overflow: hidden;
            margin-right: 15px;

            img {
              width: 100%;
              height: 100%;

              -o-object-fit: cover;
              object-fit: cover;
            }
          }

          .text {
            flex: 1;

            h6 {
              font-size: 18px;
            }

            p {
              font-size: 14px;
              color: #959595;
            }
          }
        }

        .link-box {
          padding: 15px 18px 15px;

          p {
            font-size: 14px;
            margin-bottom: 5px;
          }

          .form-group {
            position: relative;

            input {
              width: 100%;
              background-color: rgba(196, 196, 196, 0.2);
              border-radius: 3px;
              font-size: 14px;
              padding: 8px 14px;
              padding-right: 30px;
              border: none;
              outline: none;
            }

            button {
              position: absolute;
              background-color: transparent;
              right: 10px;
              top: 50%;
              -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
              border: none;
              outline: none;
              color: #dadada;
              font-size: 12px;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
