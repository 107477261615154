@import '../../setting.scss';

.authentication-group {
    padding: 70px 120px 100px;
    text-align: center;
    position: relative;
    background-color: rgba($color-white, 0.1);

    .head-box {
        p {
            margin-top: 50px;
            margin-bottom: 50px;
        }
    }

    &.error-group {
        .btn {
            color: $color-white;
        }

        p {
            font-size: 24px;
        }
    }

    

    &.sign-up {

        h3 {
            margin: 20px 0;
        }
    }

    .image-box {
        position: absolute;
        width: 500px;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);

        img {
            max-width: 100%;

        }
    }

    h1 {
        font-size: 64px;
        font-weight: 700;
        color: $color-white;
        margin-bottom: 10px;
    }

    h2 {
        color: $color-white;
        font-size: 36px;
        margin-bottom: 0;
    }

    h3 {
        font-size: 24px;
        font-weight: 400;
        margin: 20px 0 30px;

    }

    ul {
        margin-top: 30px;

        li {
            text-align: center;
            list-style: none;
            margin-bottom: 15px;

            a {
                color: $color-orange;
                text-decoration: none;
                font-weight: 800;

                span {
                    color: $color-white;
                    font-weight: 400;
                }
            }
        }
    }

    form {
        margin-top: 0;
        width: 100%;

    }

    .form-group {
        width: 100%;
        margin-bottom: 20px;

        &:nth-last-of-type(2) {
            margin-bottom: 0;
        }

        .btn {
            margin-top: 20px;
            width: 100%;
        }

        .error-input {
            p {
                color: #FF0000;
                font-size: 14px;
                margin-top: 2px;
            }
        }

        input {
            width: 100%;
            font-size: 18px;
            background-color: rgba($color-white, 0.2);
            border-radius: 3px;
            text-align: center;

            &::-webkit-input-placeholder {
                color: rgba($color-white, 0.3)
            }

            &::-moz-placeholder {
                color: rgba($color-white, 0.3)
            }

            &:-ms-input-placeholder {
                color: rgba($color-white, 0.3)
            }

            &::-ms-input-placeholder {
                color: rgba($color-white, 0.3)
            }

            &::placeholder {
                color: rgba($color-white, 0.3)
            }
        }
    }
}
