@import "../../setting.scss";

.map-box {
    position: relative;
}

.map-points {
    li {
        position: absolute;
        list-style: none;
        width: 30px;
        // height: 20px;
        // background-color:
        color: $color-black;

        &:hover {
            .tooltip {
                z-index: 9;
                opacity: 1;
                visibility: visible;
            }
        }

        p {
            position: absolute;
            right: calc(100% + 5px);
            top: 50%;
            transform: translateY(-50%);
            font-size: 14px;
            text-transform: capitalize;
            font-weight: 700;
            white-space: nowrap;
            background-color: #361a50;
            opacity: 0.8;
            color: #fff;
            padding: 10px;
        }

        .tooltip {
            background-color: #361a50;
            padding: 20px 20px;
            position: absolute;
            right: -30px;
            bottom: calc(100% + 10px);
            width: 230px;
            box-shadow: 0px 0px 20px rgba(2, 2, 2, 0.5);
            z-index: -1;
            opacity: 0;
            transition: all 0.5s;
            border-radius: 3px;
            visibility: hidden;
            cursor: pointer;

            &:after {
                content: "";
                border-top: solid 7px #361a50;
                border-left: solid 7px transparent;
                border-right: solid 7px transparent;
                position: absolute;
                top: 100%;
                right: 35px;
            }

            .time-date {
                padding: 0;
                font-size: 12px;
                margin-bottom: 5px;
                margin-top: 0;
            }

            h5 {
                color: $color-white;
                font-size: 16px;
                margin-bottom: 0;
                cursor: pointer;
                text-transform: capitalize;
            }

            span {
                color: rgba($color-white, 0.6);
                font-size: 14px;
                display: block;

                img {
                    opacity: 0.6;
                    display: inline-block;
                    vertical-align: middle;
                }

                &.live-btn {
                    background: #cf2424;
                    border-radius: 3px;
                    display: inline-block;
                    font-size: 10px;
                    padding: 3px 10px;
                    color: $color-white;
                    margin: 5px 0;

                    cursor: pointer;
                }
            }

            p {
                position: static;
                font-size: 14px;
                color: $color-white;
                transform: translate(0, 0);
                margin-top: 5px;
                font-weight: 400;
                text-transform: capitalize;
                white-space: normal;
            }
        }
    }
}

.map-section {
    // min-height: 100vh;
    position: relative;

    .map-navigation {
        position: absolute;
        top: 30px;
        left: 30px;
        z-index: 9;
        width: 200px;

        button {
            width: 100%;
            background-color: #361a50;
            padding: 15px;
            text-align: center;
            font-size: 14px;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            border: none;
            outline: none;
            color: $color-white;
            position: relative;

            i {
                position: absolute;
                top: 50%;
                transform: translateY(-50%) rotate(180deg);
                transform-origin: center;
                right: 15px;
                font-size: 20px;
                transition: all 0.5s;
            }

            &.active {
                i {
                    transform: translateY(-50%);
                }
            }
        }

        ul {

            width: 100%;
            background-color: #361a50;
            padding: 20px 0;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
            display: none;

            &.active {
                display: block;
                border-top: solid 1px rgba($color-white, 0.3);
            }

            li {
                list-style: none;
                padding: 12px 30px;

                img {
                    vertical-align: middle;
                    margin-right: 20px;
                }

                p {
                    display: inline-block;
                    vertical-align: middle;
                    font-size: 14px;
                }
            }
        }
    }

    .chat-section {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;

        .chat-open-btn {
            position: absolute;
            bottom: 0;
            left: 0;
            background-color: #4d2472;
            border: none;
            outline: none;
            z-index: 999;
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-top-right-radius: 5px;

            img {
                width: 20px;
            }

            span {
                font-size: 12px;
                opacity: 0.5;
                position: absolute;
                top: 0;
                right: 0;

                &.unseen-msg {
                    bottom: 0;
                    right: 0;
                    width: 16px;
                    height: 16px;
                    text-align: center;
                    display: inline-block;
                    border-radius: 50%;
                    line-height: 16px;
                    background-color: #1db4af;
                    opacity: 1;
                    top: auto;
                }
            }
        }

        .conversation-box {
            width: 45%;
            height: 400px;
            position: absolute;
            left: 0;
            bottom: 0;
            z-index: 99999999;

            .chat-box {
                background-color: #13143c;
            }
        }
    }
}

.mapContainer {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
}

.mainmap-box {
    width: 100%;
    height: 100vh;
}

.mapboxgl-marker {
    &:hover {
        .tooltip {
            z-index: 9;
            opacity: 1;
            visibility: visible;
        }
    }

    span {
        img {
            width: 35px;
        }
    }

    img {
        vertical-align: middle;
        margin-right: 20px;
    }

    p {
        display: inline-block;
        vertical-align: middle;
        font-size: 14px;
    }

    p {
        position: absolute;
        right: calc(100% + 5px);
        top: 50%;
        transform: translateY(-50%);
        font-size: 14px;
        text-transform: capitalize;
        font-weight: 700;
        white-space: nowrap;
        background-color: #361a50;
        opacity: 0.8;
        color: #fff;
        padding: 10px;
    }


}

.tooltip {
    background-color: #361a50;
    padding: 20px 20px;
    position: absolute;
    right: -30px;
    bottom: calc(100% + 10px);
    width: 230px;
    box-shadow: 0px 0px 20px rgba(2, 2, 2, 0.5);
    z-index: -1;
    opacity: 0;
    transition: all 0.5s;
    border-radius: 3px;
    visibility: hidden;
    cursor: pointer;

    &.saperated {
        bottom: calc(100% + 60px);
    }

    &.active {
        visibility: visible;
        opacity: 1;
        z-index: 9999;
    }


    &:after {
        content: "";
        border-top: solid 7px #361a50;
        border-left: solid 7px transparent;
        border-right: solid 7px transparent;
        position: absolute;
        top: 100%;
        right: 35px;
    }

    .time-date {
        padding: 0;
        font-size: 12px;
        margin-bottom: 5px;
        margin-top: 0;
    }

    h5 {
        color: $color-white;
        font-size: 16px;
        margin-bottom: 0;
        cursor: pointer;
        text-transform: capitalize;
    }

    span {
        color: rgba($color-white, 0.6);
        font-size: 14px;
        display: block;

        img {
            opacity: 0.6;
            display: inline-block;
            vertical-align: middle;
        }

        &.live-btn {
            background: #cf2424;
            border-radius: 3px;
            display: inline-block;
            font-size: 10px;
            padding: 3px 10px;
            color: $color-white;
            margin: 5px 0;

            cursor: pointer;
        }
    }

    p {
        position: static;
        font-size: 14px;
        color: $color-white;
        transform: translate(0, 0);
        margin-top: 5px;
        font-weight: 400;
        text-transform: capitalize;
        white-space: normal;
    }
}
