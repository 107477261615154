.create-event {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #13143C;
    flex-direction: column;

    h2 {
        font-size: 36px;
        margin-bottom: 60px;
    }
	@media (max-width: 767px){
		background: linear-gradient(180deg, #37195E 0%, rgba(66, 29, 102, 0) 100%), #361A50;
		justify-content: flex-start;
		padding-top: 50px;
		h2{
			font-size: 24px;
			margin-bottom: 0;
		}
		.create-event-box{
			width: 100%;
			box-shadow: none;
			padding: 20px 30px 40px;
		}
		.field-group{
			flex-wrap: wrap;
			justify-content: center;
			figure{
				margin-right: 0;
				margin-bottom: 30px;
			}
			.form-group{
				width: 100%;
				flex: unset;
			}
		}
	}

}
