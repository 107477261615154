@import "../../setting.scss";

.profile-group {
    border-radius: 6px;
    overflow: hidden;

    h2 {
        @extend %gradient;
        font-weight: 400;
        padding: 15px 25px;
    }

    .flex {
        padding: 30px 80px;
        align-items: flex-start;
    }

    .avtar-section {
        width: 170px;
        position: relative;

        figure {
            width: 130px;
            height: 130px;
            border-radius: 50%;
            overflow: hidden;
            margin: 0 auto 25px;

            img {
                width: 100%;
                height: 100%;
                -o-object-fit: cover;
                object-fit: cover;

            }
        }

        input {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;

        }

        .btn {
            text-align: center;
            width: 100%;
        }
    }

    .form-section {
        width: 500px;

        label {
            display: block;
            font-size: 14px;
            font-weight: 700;
            margin-bottom: 10px;

            span {
                font-size: 12px;
                font-weight: 400;
                color: rgba($color-white, .5);
                display: inline-block;
                margin-left: 15px;
            }
        }
    }

    .link-group {
        .form-group {
            display: flex;
            align-items: center;

            label {
                width: 110px;
                margin-bottom: 0;
            }

            .form-control {
                flex: 1;
                padding: 3px 15px;

            }
        }
    }

    .btn-box {
        text-align: right;

        li {
            display: inline-block;
            list-style: none;
            margin-left: 10px;
        }
    }

    .form-group {
        margin-bottom: 30px;
        &.error-group{
            .form-control{
                border: solid 2px #E84141 !important;
            }
            .input-error{
                margin-top: 5px;
                p{
                    font-size: 14px;
                    color: #E84141;
                }
            }
        }
        .form-control {
            background-color: rgba(196, 196, 196, 0.2);
            width: 100%;
            border-radius: 3px;
            color: $color-white;
            resize: none;
            font-size: 14px;
            border: solid 2px transparent;
            &::-webkit-input-placeholder {
                font-size: 14px;
            }

            &::-moz-placeholder {
                font-size: 14px;
            }

            &:-ms-input-placeholder {
                font-size: 14px;
            }

            &::-ms-input-placeholder {
                font-size: 14px;
            }

            &::placeholder {
                font-size: 14px;
            }
        }
    }

    .field-group {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: 30px;

        label {
            width: 100%;

        }

        .form-group {
            margin-bottom: 0;
            margin-left: 10px;

            &:first-of-type {
                margin-left: 0;
            }

            &.month {
                width: 75px;
            }

            &.day {
                width: 75px;
            }

            &.year {
                width: 102px;
            }
        }

        .check-group {
            margin-left: auto;
            position: relative;

            input {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                opacity: 0;
                z-index: 2;
                cursor: pointer;

                &:checked+label:before {
                    opacity: 1;
                }
            }

            label {
                font-size: 12px;
                font-weight: 400;
                color: rgba($color-white, .5);
                position: relative;
                padding-left: 28px;
                margin-bottom: 0;

                &:after,
                &:before {
                    content: "";
                    position: absolute;
                }

                &:after {
                    width: 16px;
                    height: 16px;
                    background-color: rgba(196, 196, 196, 0.2);
                    border-radius: 3px;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }

                &:before {
                    width: 8px;
                    height: 4px;
                    border-left: solid 2px $color-white;
                    border-bottom: solid 2px $color-white;
                    left: 3px;
                    top: calc(50% - 1px);
                    transform: translateY(-50%) rotate(-45deg);
                    opacity: 0;
                }



            }
        }
    }

}


.select-control {

    .css-yk16xz-control,
    .css-1pahdxg-control {
        background-color: rgba(196, 196, 196, 0.2) !important;
        border: none !important;
        outline: none;
        box-shadow: none;

        .css-g1d714-ValueContainer {
            padding: 9px 20px;
        }

        .css-g1d714-ValueContainer {
            outline: none;
        }

        .css-1wa3eu0-placeholder {
            font-size: 14px;
            color: rgba($color-white, .3);
        }


    }

    .css-26l3qy-menu {
        margin: 5px 0 !important;
        background-color: #575D64;
        font-size: 14px;
        box-shadow: 0px 5px 15px rgba(2, 2, 2, 0.4);
    }

    .css-1okebmr-indicatorSeparator {
        display: none;
    }

    .css-1uccc91-singleValue {
        color: $color-white
    }
}
