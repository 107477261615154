@import "../../setting.scss";

.feed-box {
  width: 100%;
  height: 100%;
  .main-box {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }
  .success-message {
    @media (max-width: 767px) {
      width: calc(100% - 150px);
      top: unset;
      bottom: 00px;
      &.active {
        top: unset;
        bottom: 50px;
      }
    }
  }
  @media (max-width: 767px) {
    margin-bottom: 100px !important;
  }
  .no-slide {
    height: 100%;

    figure {
      height: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: left;
      }
    }
  }

  .image-slider {
    width: 100%;
    position: relative;
    height: 100%;

    .status-box {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      @media (max-width: 767px) {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        overflow: hidden;
      }
    }
    .react-box {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      transition: all 0.5s;
      opacity: 1;
      @media (max-width: 767px) {
        top: 100%;
        height: unset;
      }
      &.active {
        opacity: 1;
      }

      ul {
        position: absolute;
        top: 50%;
        right: 17px;
        transform: translateY(-50%);
        z-index: 9;

        @media (max-width: 1200px) {
          display: none;
        }

        li {
          list-style: none;
          text-align: center;
          border: solid 1px rgba($color-white, 0.3);
          padding: 10px;
          border-radius: 50px;
          margin-bottom: 15px;
          background-color: transparent;
          cursor: pointer;
          transition: all 0.5s;
          background: rgba(19, 20, 60, 0.4);

          @media (max-width: 1500px) {
            margin-bottom: 10px;
            padding: 10px 7px;
          }

          @media (max-width: 1400px) {
            margin-bottom: 5px;
          }

          // &.active{
          //     background-color: $color-white;
          //     span{
          //         color: #13143c;
          //     }
          // }
          &:hover {
            border: solid 1px $color-white;
            background: rgba(19, 20, 60, 0.7);
          }

          &.active {
            background-color: $color-white;
            border: solid 1px $color-white;

            span {
              color: #13143c;
            }
          }

          &:last-of-type {
            margin-bottom: 0;
          }

          img {
            width: 35px;

            @media (max-width: 1500px) {
              width: 25px;
            }
          }

          span {
            display: block;
            margin-top: 5px;

            @media (max-width: 1500px) {
              margin-top: 2px;
              font-size: 14px;
            }
          }
        }
      }

      .mobile-box {
        .messsage-btn {
          display: none;
        }
        .see-more-btn {
          display: none;
        }
        @media (max-width: 767px) {
          display: block;
          background-color: #26264b;
          padding: 0px 15px;
          text-align: center;
          .messsage-btn,
          .see-more-btn,
          ul {
            display: block;
          }
          .inner-mobile-box {
            display: flex;
            padding: 10px 0;
            border-bottom: solid 1px #c1c1c1;
            justify-content: space-between;
          }
          .messsage-btn {
            background-color: transparent;
            color: $color-white;
            border: none;
            outline: none;
            font-size: 12px;

            img {
              vertical-align: middle;
            }
          }
          ul {
            display: flex;
            transform: translate(0);
            position: relative;
            margin-right: -5px;
            li {
              display: flex;
              padding: 0;
              border: none;
              background-color: transparent;
              border-radius: 0;
              align-items: center;
              margin-bottom: 0;
              padding-left: 10px;
              img {
                width: 12px;
                margin-right: 3px;
              }
            }
          }
          .see-more-btn {
            background-color: transparent;
            border: none;
            outline: none;
            display: inline-block;
            font-size: 12px;
            color: #c1c1c1;
            margin-bottom: 10px;
            margin-top: 10px;
            img {
              margin-right: 10px;
            }
          }
        }
      }

      .open-send-box {
        position: absolute;
        bottom: 15px;
        right: 75px;
        cursor: pointer;
        z-index: 99999;

        @media (max-width: 767px) {
          display: none;
          position: absolute;
          bottom: 5px;
          right: 13px;
          cursor: pointer;
        }
      }

      .backDrop {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 9999999999;
      }

      .send-box {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 15px 25px 20px;
        background-color: #381a5c;
        transform: translateY(100%);
        transition: all 0.5s;
        opacity: 0;
        z-index: -111;
        &.active {
          transform: translateY(0);
          z-index: 999999999999999999;
          opacity: 1;
        }

        // @media (max-width: 1600px) {
        //   width: 350px;
        // }

        // @media (max-width: 1400px) {
        //   bottom: 35px;
        //   left: 10px;
        //   transform: translateX(0);
        // }

        .post-box {
          display: none;

          @media (max-width: 767px) {
            display: flex;
            align-items: center;
            margin-bottom: 14px;

            figure {
              width: 68px;
              height: 40px;
              margin-right: 15px;

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }

            p {
              font-size: 14px;
              opacity: 0.8;
            }
          }
        }

        form {
          position: relative;
        }

        input {
          border: solid 1px rgba($color-white, 0.5) !important;
          background-color: transparent;
          width: 100%;
          padding: 4px 15px;
          font-size: 14px;
          padding-right: 60px;
        }

        ul {
          right: 0;
          display: flex;

          li {
            display: flex;
            border: none;
            padding: 0;
            margin-right: 14px;
            margin-bottom: 0;
            background-color: transparent;
            align-items: center;
            justify-content: center;

            &:last-of-type {
              order: -1;
              margin-right: -10px;
            }

            button {
              border: none;
              outline: none;
              background-color: transparent;
              color: $color-white;
            }
          }
        }
      }

      .bottam-navigation {
        .alert-popup {
          display: none;
          @media (max-width: 767px) {
            display: block;
          }
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba($color-black, 0.9);
          .inner-box {
            background-color: #863399;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            width: 100%;
            padding: 40px;
            text-align: center;
            position: absolute;
            figure {
              width: 50px;
              margin: 0 auto 20px;
            }
            h2 {
              font-size: 24px;
            }
            p {
              font-size: 18px;
              margin: 20px 0 30px;
            }
            .btn {
              width: auto;
              min-width: unset;
              font-size: 18px;
              background-color: rgba($color-white, 0.3);
              color: $color-white;
            }
          }
        }
        .nav {
          display: none;
        }

        .back-drop-black {
          display: none;
        }

        @media (max-width: 767px) {
          position: fixed;
          bottom: 20px;
          right: 20px;
          transition: all 0.5s;
          z-index: 9999;

          .back-drop-black {
            display: block;
            position: fixed;
            z-index: -1;
            background-color: rgba($color-black, 0.9);
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            transition: all 0.5s;
            opacity: 0;
            visibility: hidden;

            &.active {
              opacity: 1;
              z-index: 9;
              visibility: visible;
            }
          }

          .nav {
            display: block;
            position: relative;
            transform: translateY(0);
            z-index: 99;
            top: unset;
            left: unset;
            right: unset;
            bottom: unset;

            li {
              padding: 0;
              border: none;
              background-color: transparent;
              margin-bottom: 0;
              margin-top: 8px;
              position: relative;

              .unseenMsg {
                position: absolute;
                top: -5px;
                right: -3px;
                background-color: #1db4af;
                color: #fff;
                width: 16px;
                height: 16px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                font-size: 12px;
                margin-top: 0;
              }

              button {
                width: 40px;
                height: 40px;
                padding: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #863399;
                box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.25);
                border: none;
                border-radius: 50%;
                margin: 0 auto;

                img {
                  width: 20px;
                }
              }

              &.add-post-btn {
                position: relative;
                margin-top: 15px;
                > button {
                  background-color: #ffffff;
                  width: 64px;
                  height: 64px;
                  img {
                    width: 30px;
                  }
                }

                .backDrop {
                  position: fixed;
                  z-index: 99;
                }

                ul {
                  position: absolute;
                  bottom: 0;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  top: unset;
                  transform: translateY(0);
                  right: 0;
                  z-index: 9999;

                  li {
                    border: none;
                    position: relative;
                    button {
                      width: 64px;
                      height: 64px;
                    }

                    p {
                      position: absolute;
                      font-size: 14px;
                      right: 100%;
                      top: 50%;
                      transform: translateY(-50%);
                      white-space: nowrap;
                      padding-right: 10px;
                    }

                    &.video-btn {
                      button {
                        background-color: $color-white;
                        width: 32px;
                        height: 32px;

                        img {
                          width: 15px;
                        }
                      }
                    }
                    &.directUploadBox {
                      input {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        opacity: 0;
                        z-index: 999;
                        top: 0;
                        left: 0;
                      }
                    }
                  }
                }
              }
            }
          }

          .send-box {
            position: fixed;
            top: 100%;
            right: 0;
            width: 100%;
            padding: 15px 25px 20px;
            background-color: #381a5c;
            z-index: -1;
            opacity: 0;
            left: auto;
            border-top-left-radius: 19px;
            border-top-right-radius: 19px;
            transition: all 0.5s;
            bottom: unset;
          }

          &.active {
            bottom: 130px;

            .send-box {
              opacity: 1;
              z-index: 99999999999;
              // transform: translateY(0);
              transform: translateY(-100%);
            }
          }
        }
      }
    }
    &:hover {
      .react-box {
        opacity: 1;
      }
    }
  }

  &:hover {
    .status-box {
      .image-box {
        figure {
          &:after {
            background: rgba(0, 0, 0, 1);
            background: -webkit-gradient(
              left top,
              left bottom,
              color-stop(0%, rgba(0, 0, 0, 1)),
              color-stop(25%, rgba(255, 255, 255, 0)),
              color-stop(50%, rgba(255, 255, 255, 0)),
              color-stop(75%, rgba(255, 255, 255, 0)),
              color-stop(100%, rgba(0, 0, 0, 1))
            );
            background: linear-gradient(
              to bottom,
              rgba(0, 0, 0, 1) 0%,
              rgba(255, 255, 255, 0) 25%,
              rgba(255, 255, 255, 0) 50%,
              rgba(255, 255, 255, 0) 75%,
              rgba(0, 0, 0, 1) 100%
            );
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#000000', endColorstr='#000000', GradientType=0);
          }
        }
      }
    }
  }

  .add-post {
    position: absolute;
    left: 20px;
    bottom: 20px;
    z-index: 99;
    cursor: pointer;
    display: flex;
    align-items: center;

    @media (max-width: 767px) {
      display: none;
    }

    &:hover {
      .icon {
        box-shadow: 0px 0px 4px #000000;
      }
    }

    .icon {
      width: 24px;
      height: 24px;
      background-color: $color-white;
      text-align: center;
      line-height: 15px;
      border-radius: 50%;
      display: inline-block;
      position: relative;
      transition: all 0.5s;

      i {
        font-size: 14px;
        color: #843598;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    p {
      display: inline-block;
      margin-left: 10px;
      font-size: 14px;
    }

    @media (max-width: 1400px) {
      left: 10px;
      bottom: 10px;

      .icon {
        width: 15px;
        height: 15px;
        border: solid 1px $color-white;
        line-height: 8px;

        i {
          font-size: 10px;
        }
      }

      p {
        font-size: 14px;
        margin-left: 5px;
      }
    }

    ul {
      background-color: #361a50;
      border-radius: 3px;
      position: absolute;
      width: 300px;
      bottom: calc(100% + 10px);
      left: 0;

      li {
        list-style: none;
        padding: 15px 15px;

        i {
          width: 15px;
        }
      }
    }
  }

  .slider-box {
    height: 100%;

    @media (max-width: 767px) {
      position: relative;
      z-index: 9999999;
    }

    .slick-slider,
    .slick-list,
    .slick-track,
    .slick-slide {
      height: 100%;
    }

    .slick-slide {
      > div {
        height: 100%;

        > div {
          height: 100%;
        }
      }
    }
  }

  .progress-bar {
    position: absolute;
    width: calc(100% - 30px);
    left: 50%;
    top: 9px;
    height: 4px;
    border-radius: 10px;
    z-index: 9;
    transform: translateX(-50%);
    background-color: rgba($color-white, 0.5);
    overflow: hidden;

    @media (max-width: 1200px) {
      width: calc(100% - 10px);
      top: 5px;
      height: 2px;
    }

    .active {
      width: 0;
      height: 100%;
      background-color: $color-white;
      position: absolute;
      left: 0;
      top: 0;
      -webkit-animation-iteration-count: infinite;
      animation-iteration-count: infinite;
      -webkit-animation-duration: 5000;
      animation-duration: 5s;

      &.play {
        -webkit-animation-name: goFull;
        animation-name: goFull;
      }
    }
  }

  .join-url {
    position: absolute;
    z-index: 9999;
    top: 40px;
    right: 90px;

    .tooltip {
      background-color: #361a50;
      padding: 20px 20px;
      position: absolute;
      bottom: calc(100% + 10px);
      width: 230px;
      box-shadow: 0px 0px 20px rgba(2, 2, 2, 0.5);
      z-index: -1;
      opacity: 0;
      transition: all 0.5s;
      border-radius: 3px;
      left: 50%;
      transform: translateX(-50%);

      &:after {
        content: "";
        border-top: solid 7px #361a50;
        border-left: solid 7px transparent;
        border-right: solid 7px transparent;
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
      }

      h5 {
        color: $color-white;
        font-size: 16px;
        margin-bottom: 5px;
        cursor: pointer;
        // text-transform: capitalize;
      }

      span {
        color: rgba($color-white, 0.6);
        font-size: 14px;
        display: block;

        img {
          opacity: 0.6;
          display: inline-block;
          vertical-align: middle;
        }

        &.live-btn {
          background: #cf2424;
          border-radius: 3px;
          display: inline-block;
          font-size: 10px;
          padding: 3px 10px;
          color: $color-white;
          margin: 5px 0;

          cursor: pointer;
        }
      }

      p {
        position: static;
        font-size: 14px;
        color: $color-white;
        transform: translate(0, 0);
        margin-top: 5px;
        font-weight: 400;
        text-transform: capitalize;
      }
    }

    .btn {
      padding: 5px 15px;
      min-width: auto;
      background-color: transparent;
      border: solid 1px $color-white;
      color: $color-white;
      font-size: 14px;

      img {
        width: 13px;
        margin-left: -8px;
        height: auto;
        margin-right: 2px;
        margin-top: 2px;
      }

      &:hover {
        + .tooltip {
          z-index: 9;
          opacity: 1;
        }
      }
    }
  }

  .status-box {
    position: relative;
    height: 100%;
    opacity: 0;
    transition: all 0.5s;
    z-index: -1;

    &.active {
      opacity: 1;
      z-index: 2;
    }

    .image-box {
      height: 100%;

      figure {
        height: 100%;
        text-align: center;

        img {
          width: auto;
          height: 100%;
        }

        &:after {
          transition: all 0.5s;
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 1);
          background: -webkit-gradient(
            left top,
            left bottom,
            color-stop(0%, rgba(0, 0, 0, 1)),
            color-stop(25%, rgba(255, 255, 255, 0)),
            color-stop(50%, rgba(255, 255, 255, 0)),
            color-stop(75%, rgba(255, 255, 255, 0)),
            color-stop(100%, rgba(0, 0, 0, 1))
          );
          background: linear-gradient(
            to bottom,
            rgba(0, 0, 0, 1) 0%,
            rgba(255, 255, 255, 0) 25%,
            rgba(255, 255, 255, 0) 50%,
            rgba(255, 255, 255, 0) 75%,
            rgba(0, 0, 0, 1) 100%
          );
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#000000', endColorstr='#000000', GradientType=0);
        }
      }
    }

    .user-detail {
      position: absolute;
      top: 20px;
      left: 20px;
      z-index: 999;

      @media (max-width: 1200px) {
        top: 15px;
        left: 10px;
      }

      figure {
        width: 56px;
        height: 56px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 20px;

        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          border: solid 2px $color-white;
          -o-object-fit: cover;
          object-fit: cover;
        }

        @media (max-width: 1200px) {
          width: 45px;
          height: 45px;
          margin-right: 10px;

          img {
            border: solid 1px $color-white;
          }
        }
      }

      .text-box {
        display: inline-block;
        vertical-align: middle;

        p {
          font-size: 17px;
          font-weight: 700;
        }

        span {
          display: block;
          margin-top: 5px;
          font-size: 14px;
        }

        @media (max-width: 1200px) {
          p {
            font-size: 14px;
          }

          span {
            margin-top: 1px;
            font-size: 12px;
          }
        }
      }
    }
  }
}

.add-post-btn-box {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 9999999999999999999;
  li {
    list-style: none;
    padding: 0;
    border: none;
    background-color: transparent;
    margin-bottom: 0;
    margin-top: 8px;
    position: relative;

    .unseenMsg {
      position: absolute;
      top: -5px;
      right: -3px;
      background-color: #1db4af;
      color: #fff;
      width: 16px;
      height: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      font-size: 12px;
      margin-top: 0;
    }

    button {
      width: 40px;
      height: 40px;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #863399;
      box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.25);
      border: none;
      border-radius: 50%;
      margin: 0 auto;

      img {
        width: 20px;
      }
    }

    &.add-post-btn {
      position: relative;
      margin-top: 15px;
      > button {
        background-color: #ffffff;
        width: 64px;
        height: 64px;
        img {
          width: 30px;
        }
      }

      .backDrop {
        position: fixed;
        z-index: 99;
      }

      ul {
        position: absolute;
        bottom: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        top: unset;
        transform: translateY(0);
        right: 0;
        z-index: 9999;

        li {
          border: none;
          position: relative;
          button {
            width: 64px;
            height: 64px;
          }

          p {
            position: absolute;
            font-size: 14px;
            right: 100%;
            top: 50%;
            transform: translateY(-50%);
            white-space: nowrap;
            padding-right: 10px;
          }

          &.video-btn {
            button {
              background-color: $color-white;
              width: 32px;
              height: 32px;

              img {
                width: 15px;
              }
            }
          }
          &.directUploadBox {
            input {
              position: absolute;
              width: 100%;
              height: 100%;
              opacity: 0;
              z-index: 999;
              top: 0;
              left: 0;
            }
          }
        }
      }
    }
  }
}
